import React, { useState } from 'react';

import { TruncateText } from '@ansarada/ace-react';
import { TextInput } from '@common/admin';

import {
  MEMOLIMIT,
} from '@lib/limits';

import LocationIcon from '@image/icon/icon-location.svg';
import TeamsIcon from '@image/logo/microsoft-teams-1.svg';
import WebExIcon from '@image/logo/webex-logo-2.svg';
import ZoomIcon from '@image/logo/zoom-app.svg';

export const TextLocation = (props) => {
  const [isEdit, setEdit] = useState(false)
  let location = <label className="nextNoLoc">{props.onChange?"Add location":"No location"}</label> 
  let icon = <img src={LocationIcon}/>
  const name = props.value.trim()
  if(name !== ""){
    if(name.toLowerCase().indexOf("http") === 0){
      if(name.toLowerCase().indexOf("webex.com") !== -1){
        icon = <img src={WebExIcon}/>
        location = <label> WebEx Video Conference link</label>
      }else if(name.toLowerCase().indexOf("zoom.us") !== -1){
        icon = <img src={ZoomIcon}/>
        location = <label>Zoom Video Conference link</label>
      }else if(name.toLowerCase().indexOf("teams.microsoft") !== -1){
        icon = <img src={TeamsIcon}/>
        location = <label>Microsoft Teams Video Conference link</label>
      }else
        location = <label>External link</label>
    }else {
      location = <label data-sl="mask" className="fs-exclude" style={{width: 170}}><TruncateText truncatePosition="Tail">{name}</TruncateText></label>
    }
  }

  if(isEdit)
    return (
      <TextInput
        name="meetingLoc"
        type="location"
        stylenormal="bindertextInput"
        styleempty="bindertextInputNew"
        excludeAnalytics={true}
        autoFocus={true}
        onChange={(e)=>{
          if(props.onChange)
            return props.onChange(e)
        }}
        onFocusOut={()=>{
          setEdit(false)
        }}
        value={props.value}
        initial="Add meeting location"
        maxLength={MEMOLIMIT}
      />
    )

  return (
    <div className="nextCalLoc" style={{marginTop: 5}} onClick={()=>{
      if(props.onChange)
        setEdit(true)
    }}>
      <div style={{minWidth: 32}}>
        {icon}
      </div>
      {location}
    </div>
  )
}
