import React from 'react';
import { connect } from 'react-redux';
import { FileTypeEnum, DateFormat, UsageType } from '@constants/common.constants';
import { Loader } from '@common';
import { MuiButton, MuiSelect } from '@common/MUI';
import { DatePicker } from "@mui/lab";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  FaAngleUp as UpIcon,
  FaAngleDown as DownIcon,
} from 'react-icons/fa';
import moment from 'moment';
import {
  cmpWord,
  cmpDate,
  GetTypeName,
  DownloadBlob,
} from '@lib/simpletools';
import {SettingStorage} from '@lib/indexeddb';
import DocumentImage from '@common/documentImage';
import IconCsv from '@image/icon/CSV.svg';

import * as reportLib from '@common/reports';

import '@pages/binderspage/binder.css';

class UsageBoxClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUsageFilter: false,
      usageMinDate: this.props.startDate,
      usageMaxDate: moment(new Date()),
      usageNameList: this.props.usageNameList!==undefined?this.props.usageNameList:[],
      usageName: "all",
      usageTypeList: this.props.usageTypeList!==undefined?this.props.usageTypeList:[],
      usageType: "all",
      direction: false,
      showExport: false,
      exportHeight: 200,
      sortUser: true
    }

    this.refPanel = null
  }

  componentDidMount() {
    SettingStorage.Get(this.props.username+'useageSort')
    .then((data)=>{
      this.setState({direction: data.key});
    }).catch((e)=>{})
    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(Array.isArray(nextProps.usages) && state.usageNameList.length === 0){
      let usageTypeList = []
      nextProps.usages.forEach(item =>{
        if(!usageTypeList.includes(item.action))
          usageTypeList.push(item.action)
      })
      return {
        usageTypeList
      }
    }

    return null
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  onLogMinDateChange(date){
    var m = moment(date);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);
    this.setState({usageMinDate: m});
  }

  onLogMaxDateChange(date){
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({usageMaxDate: m});
  }

  onLogNameChange(value){
    this.setState({usageName: value});
  }

  onLogTypeChange(value){
    this.setState({usageType: value});
  }

  onSetOrder(){
    this.setState({direction: !this.state.direction})
    SettingStorage.Put({id: this.props.username+'useageSort', key: this.state.direction})
    .then(()=>{}).catch((e)=>{});
  }

  onExport(exportType){
    if(this.props.itemIds.length === 0 || this.props.usageIds.length === 0)
      return

    var List = [], GroupBy=[];
    for(var x=0; x<this.props.itemIds.length; x++){
      var itemId = this.props.itemIds[x]
      if(this.props.bindersStats[itemId] === undefined) continue
      if(this.props.binderItems[itemId] === undefined) continue
      if(this.props.binderItems[itemId].name === "") continue

      var itemName = this.props.binderItems[itemId].name

      for(var key in this.props.bindersStats[itemId]){
        if(this.props.usageIds.indexOf(key) === -1) continue
        //Get user name
        var userId = this.props.bindersStats[itemId][key].userId
        if(this.props.users[userId] === undefined) continue
        if(this.props.users[userId].firstName === "" && this.props.users[userId].lastName) continue
        const name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
        const type = this.props.bindersStats[itemId][key].usageType

        //Convert strin date to js date
        try{
          var date = new Date(this.props.bindersStats[itemId][key].usageDate)
          var m = moment(date)

          var d = m.format('LL')
          if(!GroupBy.includes(d) && exportType === FileTypeEnum.pdf)
            GroupBy.push(d)
        }catch(e){
          continue
        }

        List.push({
          id: key,
          itemName: itemName,
          name: name,
          type: type,
          information: this.props.bindersStats[itemId][key].usageInformation,
          date: date,
        })
      }
    }

    //sort by date, then name, then type
    List.sort(function(a, b) {
      return cmpDate(b.date,a.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id)
    })

    GroupBy.sort(function(a, b) {
      return cmpDate(new Date(b),new Date(a))
    })

    var filename = "usage log"
    if(this.props.binderId !== undefined && this.props.binders[this.props.binderId] !== undefined)
      filename = this.props.binders[this.props.binderId].name + " usage log"

    if(exportType === FileTypeEnum.pdf){
      filename += ".pdf"
        var MyDoc = new reportLib.SummarReportPDFMake();

        MyDoc.logs({
          groupBy: GroupBy,
          list: List,
          myName: this.state.sortUser?this.props.firstName + " " + this.props.lastName:this.props.lastName + ", " + this.props.firstName,
        })
        .then((pdfcode)=>{
          reportLib.GeneratePDFBuffer(pdfcode, true)
          .then((blob)=>{
            DownloadBlob(filename, blob)
          })
        })
        .catch((e)=>{
        })
    }else if(exportType === 'csv'){
      filename += '.csv'
      var str = "Date,Type,Item,User,Action\n"
      List.forEach((row)=>{
        str += [row.date, row.type, row.itemName, row.name, row.information].join(",")+"\n"
      })

      DownloadBlob(filename, new Blob([str], {type: 'text/csv'} ))
    }
  }

  onExportSelection(e){
    e.stopPropagation()
    var s = this.refPanel.clientHeight
    this.setState({showExport: true, exportHeight: s})
  }

  clearExport(){
    this.setState({showExport: false})
  }

  renderBinderLogs(){
    var array = [];
    if(this.props.bindersStats === undefined || this.props.users === undefined || this.props.binderItems === undefined || this.props.loadingUsers) {
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    if(this.props.itemIds.length === 0 || this.props.usageIds.length === 0){
      return array
    }

    var List = [], GroupBy=[];
    var _this = this
    function renderStatus(itemId, itemName){
      if(_this.props.bindersStats[itemId] === undefined) return;

      for(var key in _this.props.bindersStats[itemId]){
        if(_this.props.usageIds.indexOf(key) === -1) continue;
        //Get user name
        var userId = _this.props.bindersStats[itemId][key].userId;
        if(_this.props.users[userId] === undefined) continue;
        if(_this.props.users[userId].firstName === "" && _this.props.users[userId].lastName) continue;
        const name = _this.props.users[userId].firstName + " " + _this.props.users[userId].lastName;
        const type = _this.props.bindersStats[itemId][key].usageType;
        if(_this.state.usageName !== "all"){
          if(_this.state.usageName !== name)
            continue;
        }
        if(_this.state.usageType !== "all"){
          if(_this.state.usageType !== type)
            continue;
        }
        //Convert strin date to js date
        try{
          var date = new Date(_this.props.bindersStats[itemId][key].usageDate);
          var m = moment(date);
          if(m < _this.state.usageMinDate)
            continue;
          if(m > _this.state.usageMaxDate)
            continue;

          var d = m.format('LL');
          if(!GroupBy.includes(d))
            GroupBy.push(d);
        }catch(e){
          continue;
        }

        List.push({
          id: key,
          itemName: itemName,
          name: name,
          userId,
          type: type,
          information: _this.props.bindersStats[itemId][key].usageInformation,
          date: date,
        })
      }
    }

    if(this.props.binders[this.props.binderId] !== undefined && this.props.binders[this.props.binderId].name !== "")
      renderStatus(this.props.binderId, this.props.binders[this.props.binderId].name)
    this.props.itemIds.forEach( itemId => {
      if(this.props.binderItems[itemId] === undefined) return;
      if(this.props.binderItems[itemId].name === "") return;
      var itemName = this.props.binderItems[itemId].name;
      renderStatus(itemId, itemName)
    })

    //sort by date, then name, then type
    if(!this.state.direction)
      List.sort(function(a, b) {
        return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
      })
    else
      List.sort(function(a, b) {
        return cmpDate(b.date,a.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
      })

    if(!this.state.direction)
      GroupBy.sort(function(a, b) {
        return cmpDate(new Date(a),new Date(b));
      })
    else
      GroupBy.sort(function(a, b) {
        return cmpDate(new Date(b),new Date(a));
      })

    var pos=0;
    for(var y=0; y<GroupBy.length; y++){
      var dayEnd = moment(GroupBy[y]);
      dayEnd.hours(23);
      dayEnd.minutes(59);
      dayEnd.seconds(59);

      var dayStart = dayEnd.clone();
      dayStart.hours(0);
      dayStart.minutes(0);
      dayStart.seconds(0);


      var inner = [];
      var LastPerson = "";
      for(var x=pos; x<List.length; x++){
        console.log(List[x]);
        var o = moment(new Date(List[x].date));
        if(!this.state.direction){
          if(o >= dayEnd ){///&& o >= dayStart
            pos = x;
            break;
          }
        }else{
          if(o <= dayStart ){///&& o >= dayStart
            pos = x;
            break;
          }
        }
        if(LastPerson !== List[x].name){
          LastPerson = List[x].name;
          inner.push(
            <div data-sl="mask" key={(x).toString()+"t"} className="fs-exclude logName" id={"userId-"+List[x].userId}>{List[x].name}</div>
          );
        }
        if(List[x].type === UsageType.DocumentShared){
          var sentTo = List[x].information.split(",");
          inner.push(
            <div key={(x).toString()} className="logText" id={"itemId-"+List[x].id}>
              {o.format('hh:mm a')}: <label>{GetTypeName(List[x].type)}</label>&nbsp;
              item <label data-sl="mask" className="fs-exclude" id={"itemId-"+List[x].id}>{List[x].itemName}</label>&nbsp;
              to <label data-sl="mask" className="fs-exclude">{sentTo[0]} {sentTo[1]}</label> email <label data-sl="mask" className="fs-exclude">{sentTo[2]}</label>
            </div>
          );
        }else if (List[x].type == UsageType.ContributorFileAddedToBinder) {
          inner.push(
            <div key={(x).toString()} className="logText" id={"itemId-"+List[x].id}>
              {o.format('hh:mm a')}: <label>{GetTypeName(List[x].type)}</label>:&nbsp;
              <label data-sl="mask" className="fs-exclude">{List[x].information} by {List[x].name}</label>
            </div>
          );
        }else{
          inner.push(
            <div key={(x).toString()} className="logText" id={"itemId-"+List[x].id}>
              {o.format('hh:mm a')}: <label>{GetTypeName(List[x].type)}</label>&nbsp;
              item <label data-sl="mask" className="fs-exclude">{List[x].itemName}</label>
            </div>
          );
        }
      }

      array.push(
        <div className="logPanel" key={y} style={{marginBottom: 10}}>
          <div className="logDate">{moment(dayEnd).format('LL')}</div>
          {inner}
        </div>
      );
    }

    return array;
  }

  renderUserLogs(){
    var array = [];
    if(this.props.usages === undefined || this.props.usages === false || !Array.isArray(this.props.usages)) {
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    var List = [], GroupBy=[];

    this.props.usages.forEach( item => {
      if(this.state.usageType !== "all"){
        if(this.state.usageType !== item.action)
          return;
      }

      //Convert strin date to js date
      try{
        var date = new Date(item.date);
        var m = moment(date);
        if(m < this.state.usageMinDate)
          return;
        if(m > this.state.usageMaxDate)
          return;

        var d = m.format('LL');
        if(!GroupBy.includes(d))
          GroupBy.push(d);
      }catch(e){
        return;
      }

      List.push({
        id: item.id,
        itemName: item.action,
        name: this.props.name,
        userId: this.props.id,
        type: item.action,
        information: item.data !== undefined? item.data : "",
        date: date,
      })
    })

    //sort by date, then name, then type
    if(!this.state.direction)
      List.sort(function(a, b) {
        return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
      })
    else
      List.sort(function(a, b) {
        return cmpDate(b.date,a.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
      })

    if(!this.state.direction)
      GroupBy.sort(function(a, b) {
        return cmpDate(new Date(a),new Date(b));
      })
    else
      GroupBy.sort(function(a, b) {
        return cmpDate(new Date(b),new Date(a));
      })

    var pos=0;
    for(var y=0; y<GroupBy.length; y++){
      var dayEnd = moment(GroupBy[y]);
      dayEnd.hours(23);
      dayEnd.minutes(59);
      dayEnd.seconds(59);

      var dayStart = dayEnd.clone();
      dayStart.hours(0);
      dayStart.minutes(0);
      dayStart.seconds(0);


      var inner = [];
      var LastPerson = "";
      for(var x=pos; x<List.length; x++){
        var o = moment(new Date(List[x].date));
        if(!this.state.direction){
          if(o >= dayEnd ){///&& o >= dayStart
            pos = x;
            break;
          }
        }else{
          if(o <= dayStart ){///&& o >= dayStart
            pos = x;
            break;
          }
        }
        if(LastPerson !== List[x].name){
          LastPerson = List[x].name;
          inner.push(
            <div key={(x).toString()+"t"} data-sl="mask" className="fs-exclude logName" id={"userId-"+List[x].userId}>{List[x].name}</div>
          );
        }
        if(List[x].information !== ""){
          inner.push(
            <div key={(x).toString()} className="logText boardpanel" id={"itemId-"+List[x].id}>
                <span style={{minWidth: 80}}>{o.format('hh:mm a')}:</span>
                 <label>
                  {GetTypeName(List[x].type)}
                  <br/>
                  {List[x].information}
                </label>
            </div>
          );
        }else{
          inner.push(
            <div key={(x).toString()} className="logText boardpanel" id={"itemId-"+List[x].id}>
              <span style={{minWidth: 80}}>{o.format('hh:mm a')}:</span><label>{GetTypeName(List[x].type)}</label>
            </div>
          );
        }
      }

      array.push(
        <div className="logPanel" key={y} style={{marginBottom: 10}}>
          <div className="logDate">{moment(dayEnd).format('LL')}</div>
          {inner}
        </div>
      );
    }

    return array;
  }

  render(){
    var loading = this.props.bindersStats === undefined || this.props.users === undefined || this.props.binderItems === undefined || this.props.loadingUsers
    let users = [{label: "All", value: 'all'}]
    if(this.state.usageNameList.length > 0){
      this.state.usageNameList.forEach(name=>{
        users.push({label: name, value: name})
      })
    }
    let types = [{label: "All", value: 'all'}]
    if(this.state.usageTypeList.length > 0){
      this.state.usageTypeList.forEach(type=>{
        types.push({label: GetTypeName(type), value: type})
      })
    }

    return(
      // <div className="aBoxTop-overlay" onClick={this.clearExport.bind(this)}>
        <Dialog
          fullWidth
          maxWidth='md'
          open={this.props.open}
        >
          <DialogTitle>
            <div className="boardpanel spacebetween binder-modal-align">
              <div className="page">
                <strong className='bold'>{this.props.title ? this.props.title : 'Binder usage logs'}</strong>
              </div>
              <IconButton onClick={this.onExit.bind(this)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className='text16s' style={{ marginTop: 10 }}>{this.props.name}</div>
            {!this.state.showUsageFilter &&
              <div className="boardpanel spacebetween">
                {loading ?
                  <div></div>
                  :
                  <div className="text14s colorAnsarada link" onClick={this.onExportSelection.bind(this)}>Export</div>
                }
                <div className="boardpanel">
                  <div className="text14s colorAnsarada link" style={{ marginRight: 10 }} onClick={this.onSetOrder.bind(this)}>Order{this.state.direction ? <DownIcon /> : <UpIcon />}</div>
                  <div className="text14s colorAnsarada link" onClick={() => { this.setState({ showUsageFilter: true }) }}>Filter <DownIcon /></div>
                </div>
              </div>
            }
            {this.state.showUsageFilter &&
              <div>
                <div className="boardpanel flexend">
                  <div className="text14s colorAnsarada link" style={{ marginRight: 10 }} onClick={this.onSetOrder.bind(this)}>Order{this.state.direction ? <DownIcon /> : <UpIcon />}</div>
                  <div className="text14s colorAnsarada link" onClick={() => { this.setState({ showUsageFilter: false }) }}>Filter <UpIcon /></div>
                </div>
              <div style={{paddingTop: 10}}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <DatePicker
                      label='Start Date'
                      value={this.state.usageMinDate.toDate()}
                      renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                      minDate={moment(this.props.startDate)}
                      onChange={this.onLogMinDateChange.bind(this)}
                      inputFormat="DD/MM/YYYY"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <DatePicker
                      label='End Date'
                      value={this.state.usageMaxDate.toDate()}
                      renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                      maxDate={moment()}
                      onChange={this.onLogMaxDateChange.bind(this)}
                      inputFormat="DD/MM/YYYY"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <MuiSelect
                      label="Name"
                      size="small"
                      options={users}
                      onChange={this.onLogNameChange.bind(this)}
                      value={this.state.usageName}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <MuiSelect
                      label="Type"
                      size="small"
                      options={types}
                      onChange={this.onLogTypeChange.bind(this)}
                      value={this.state.usageType}
                    />
                  </Grid>
                </Grid>
              </div>
              </div>
            }
          </DialogTitle>
          {this.state.showExport ?
            <div className="page" style={{height: '500px'}}>
              <div className="aPopup-panel" style={{height: "calc(500px - 60px)"}}>
                <div className="page">
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, FileTypeEnum.pdf)}>
                    <DocumentImage
                      className='link'
                      size={64}
                    />
                    <div className="centerVFlex">
                      <div className="resolutionItem page link">
                        <label className='link'>Single PDF</label>
                        <span>Combine all usage into a single PDF document</span>
                      </div>
                    </div>
                  </div>
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, "csv")}>
                    <img className='zipIcon link' src={IconCsv}/>
                    <div className="centerVFlex">
                      <div className="resolutionItem page link">
                        <label className='link'>CSV (Comma Separated Values)</label>
                        <span>Export usage results table to CSV file</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boardpanel flexend" style={{padding: 10, height: 40}}>
                <MuiButton
                  style={{marginRight:10}}
                  variant='outlined'
                  onClick={this.clearExport.bind(this)}>
                  Cancel
                </MuiButton>
              </div>
            </div>
            :
            <div className="aPopup-panel" style={{height: '500px'}} ref={(r)=>{this.refPanel=r}}>
              {this.props.userId !== undefined ?
                this.renderUserLogs()
                :
                this.renderBinderLogs()
              }
            </div>
          }
          {/* {this.state.showExport && (
            <DialogActions>
              <MuiButton variant='outlined' onClick={this.clearExport.bind(this)}>
                Cancel
              </MuiButton>
            </DialogActions>
          )} */}
        </Dialog>
        /* <div className="aPopup-box page" style={{maxHeight: '80vh'}}>
          <div className="aPopup-HeaderNB">
            <ExitIcon size={24} className="logExit" color='#999999' onClick={this.onExit.bind(this)}/>
            <div>
              <h1 style={{marginBottom: 11}}>Binder Usage Logs</h1>
              <div style={{marginBottom: 30}}>{this.props.name}</div>
              
            </div>
          </div>
          {this.state.showExport ?
            <div className="page" style={{height: this.state.exportHeight}}>
              <div className="aPopup-panel">
                <div className="page">
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, FileTypeEnum.pdf)}>
                    <DocumentImage
                      size={64}
                    />
                    <div className="centerVFlex">
                      <div className="resolutionItem page">
                        <label>Single PDF</label>
                        <span>Combine all usage into a single PDF document</span>
                      </div>
                    </div>
                  </div>
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, "csv")}>
                    <img className='zipIcon' src={IconCsv}/>
                    <div className="centerVFlex">
                      <div className="resolutionItem page">
                        <label>CSV (Comma Separated Values)</label>
                        <span>Export usage results table to CSV file</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boardpanel flexend" style={{padding: 10, height: 40}}>
                <MuiButton
                  style={{marginRight:20}}
                  variant='outlined'
                  onClick={this.clearExport.bind(this)}>
                  Cancel
                </MuiButton>
              </div>
            </div>
            :
            <div className="aPopup-panel" ref={(r)=>{this.refPanel=r}}>
              {this.props.userId !== undefined ?
                this.renderUserLogs()
                :
                this.renderBinderLogs()
              }
            </div>
          }

        </div> */
      // </div>
    )
  }
}

function mapStateToProps(state) {
  const  { username, firstName, lastName } = state.authentication;
  return {
    username,
    firstName,
    lastName,
  };
}

const connectedBinderUsageBox = connect(mapStateToProps)(UsageBoxClass);
export { connectedBinderUsageBox as UsageBox };
