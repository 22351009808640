import React from 'react';
import { Tooltip, IconButton, Portal } from '@mui/material';
import { MuiButton } from '@common/MUI';
import { Loader } from './index';
import { Progressbar } from '@ansarada/ace-react';

import { pdfjs } from 'react-pdf';
//import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
//import { Document } from "react-pdf/dist/entry.webpack";
//import {AnnotationFactory} from 'annotpdf';
import { fabric } from "fabric";
//import "react-pdf/dist/Page/AnnotationLayer.css"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '@styles/pdfviewer.css';
pdfjs.GlobalWorkerOptions.workerSrc = appConfig.pdfWorker

import {
  FaTimes as ExitIcon,
  FaTable as SideIcon,
  FaReply as BackIcon,
  FaExpand as FullScreenIcon,
  FaCompress as ExitFullScreenIcon,
  FaRegWindowMaximize as SingleSideIcon,
  FaArrowLeft as LeftIcon,
  FaArrowRight as RightIcon,
  FaPenNib as AnnaPen,
  FaCaretDown as DownArrow,
  FaTrash as TrachIcon,
  //FaCheck as TickIcon,
  FaVectorSquare as RectIcon,
  FaFont as FontIcon,
} from 'react-icons/fa';
import {
  FiSidebar as TableIcon,
} from 'react-icons/fi';
//import ProgressBar from './progressBar';
import { InView } from 'react-intersection-observer'
import ScrollContainer from './scrollContainer'
import $ from 'jquery';
//import { SimplePrint } from '@common/printSimple';

export class PDFDoc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      p: null
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.item.file !== null) return null
    const id = nextProps.item.id //nextProps.item.documentId
    if(id === undefined || id === "") return null
    if(nextProps.Queue[id] === undefined) return null
    return {
      p: nextProps.Queue[id].overallPercent
    }
  }

  render(){
    var style = Object.assign({}, this.props.style);
    style.paddingTop = 100
    style.flex = 1;
    if(this.props.item.file === null && this.state.p !== null){
      return (
        <div className="centerFlex" style={style}>
          <div className="page">
            <span className="text16s">Downloading document</span>
            <div className="centerFlex">
              <div style={{width: 150, height: 15, marginTop: 20}}>
                {/*<ProgressBar
                  success={this.state.p}
                  height={2}
                  className="progressBar2"
                  classNameBar='progressBarSuccess'
                />*/}
                <Progressbar
                  percentValue={this.state.p}
                  />
              </div>
            </div>
          </div>
        </div>
      )
    }
    if(this.props.item.file === null)
      return (
        <div className="centerFlex" style={style}>
          <div className="page">
            <span className="pdfLoading">Please wait rendering pdf</span>
            <div className="maxWidth centerFlex"><Loader small={true}/></div>
          </div>
        </div>
      )

    return(
      <Document
        file={this.props.item.file}
        inputRef={this.props.forwardRef}
        loading={
          <div className="centerFlex" style={style}>
            <Loader small={true}/>
          </div>
        }
        {...this.props}
        />
    );
  }
}

export class PDFPage extends React.Component {
  constructor(props) {
    super(props);

    var width = 119
    var height = 168
    var scale = 0.9
    var boxwidth = 119
    var boxheight = 168
    var threshold = 0.7

    if(this.props.defaultWidth !== undefined){
      width = this.props.defaultWidth;
      boxwidth = this.props.defaultWidth;
      if(this.props.scale !== undefined){
        boxwidth = this.props.defaultWidth * this.props.scale;
      }
    }
    if(this.props.defaultHeight !== undefined){
      height = this.props.defaultHeight;
      boxheight = this.props.defaultHeight;
      if(this.props.scale !== undefined){
        boxheight = this.props.defaultHeight * this.props.scale;
      }
    }
    // if(this.props.scale !== undefined){
    //   scale = this.props.scale;
    //   threshold = threshold / scale;
    //   if(threshold > 1) threshold = 1
    //}

    this.state = {
      inview: this.props.pageNumber == 1? true: false,
      loading: true,
      render: true,
      width: width,
      height: height,
      pageWidth: 0,
      pageHeight: 0,
      rotation: 0,
      boxwidth: boxwidth,
      boxheight: boxheight,
      scale: 1, //scale,
      threshold: 1,
      ignore: this.props.pageNumber === 2?true: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var initial = {}

    if(state.pageWidth === 0 || state.pageHeight === 0){
      if(nextProps.width !== undefined){
        initial.width = nextProps.width;
        initial.boxwidth = nextProps.width;
        if(state.scale !== undefined){
          initial.boxwidth = nextProps.width * state.scale;
        }
      }
      if(nextProps.height !== undefined){
        initial.height = nextProps.height;
        initial.boxheight = nextProps.height;
        if(state.scale !== undefined){
          initial.boxheight = nextProps.height * state.scale;
        }
      }
    }else{
      if(state.pageWidth !== undefined){
        initial.width = state.pageWidth;
        initial.boxwidth = state.pageWidth;
        if(nextProps.scale !== undefined){
          initial.boxwidth = state.pageWidth * nextProps.scale;
        }
      }
      if(state.height !== undefined){
        initial.height = state.pageHeight;
        initial.boxheight = state.pageHeight;
        if(nextProps.scale !== undefined){
          initial.boxheight = state.pageHeight * nextProps.scale;
        }
      }

      if(nextProps.scale !== undefined){
        initial.scale = nextProps.scale;
        initial.threshold = 0.7 / initial.scale;
        if(initial.threshold > 1) initial.threshold = 1
      }
    }
    return initial
  }

  onTotalView(inView, entry){
    //console.log("b",this.props.pageNumber,inView);
    if(this.state.ignore && inView){
      this.setState({ignore: false})
      return;
    }
    if(this.props.onPageView !== undefined && inView)
      this.props.onPageView(this.props.pageNumber);
  }

  onInView(inView, entry){
    //console.log("a",this.props.pageNumber,inView);
    if(inView)
      this.setState({inview: true})
  }

  onLoadSuccess(page){
    var viewport = page.getViewport({scale: this.state.scale});

    this.setState({pageWidth: viewport.width, pageHeight: viewport.height, rotation: viewport.rotation})

    this.setState({loading: false})
    if(this.props.onPageloaded !== undefined)
      this.props.onPageloaded(page, viewport)
  }

  render(){
    var style={
      width: this.state.boxwidth,
      height: this.state.boxheight,
    }
    var pdfWidth = this.state.width;
    var pdfHeight = this.state.height;

    // if (this.props.isShowingThumbnail && !this.props.isPreviewPage && style.width > 500) {
    //   style.height = style.height * ((style.width - 330) / style.width);
    //   pdfHeight = style.height;

    //   style.width = style.width - 330;
    //   pdfWidth = this.state.width - 330;
    // }

    if (this.props.isPreviewPage) {
      style.height = style.height * (250 / style.width);
      pdfHeight = style.height;
      style.width = 250;
      pdfWidth = 230;
    }

    try {
      style.height = document.getElementById("minute-new-page-pdf-container").clientHeight;
    } catch { }

    const { naked, lastPage} = this.props;
    if(!naked){
      style.padding = '20px';
    }else{
      if(!lastPage)
        style.marginBottom = '40px'
    }

    //root={this.props.root}
    return(
      <div ref={this.props.forwardRef} style={style} data-sl="mask">
        <InView as="div" onChange={this.onInView.bind(this)}>
          <InView as="div" onChange={this.onTotalView.bind(this)} threshold={this.state.threshold} className={this.props.hover === true?'pdfIcon':"centerFlex"}>
            {this.state.inview ?
              <div>
                <Page
                  {...this.props}
                  onLoadSuccess={this.onLoadSuccess.bind(this)}
                  onRenderSuccess={()=>{this.setState({render: false})}}
                  loading=""
                  className={`${this.state.render?"pdfblank ":""}${this.props.className !== undefined?this.props.className:""}${this.props.select?" pdfContentSel":""}`}
                  width={pdfWidth}
                  height={pdfHeight}
                  scale={this.state.scale}
                  rotate={this.state.rotation}
                >

                </Page>
                {this.state.render &&
                  <div style={{marginTop: (0 - this.state.boxheight/2), marginLeft: 500+((style.width/2)-8)}}>
                    <Loader small={true}/>
                  </div>
                }
              </div>
              :
              <div className="pdfblank" style={{width: style.width, height: this.state.boxheight}}>

              </div>
            }
          </InView>
        </InView>
      </div>
    );
  }
}

function getFiles(files){
  var results = [];
  files.forEach((f, x) => {
    var j = {
      id: results.length,
      title: "",
      file: null,
      positionString: "",
      position: f.position !== undefined? f.position: x,
      verify: null,
    }
    if(f.data !== undefined)
      j.file = f.data;
    else j.file = f;
    if(f.title !== undefined){
      j.title = f.title;
    }else if(f.itemName !== undefined){
      j.title = f.itemName
    }else if(f.name !== undefined){
      j.title = f.name;
    }else{
      j.title = "Unknown";
    }
    if(f.id !== undefined){
      j.id = f.id
    }
    if(f.documentId !== undefined){
      j.documentId = f.documentId
    }
    if(f.positionString !== undefined)
      j.positionString = f.positionString
    if(f.file === null){
      j.file = null
    }

    results.push(j)
  })

  return results;
}

export class PDFBase extends React.Component {
  constructor(props) {
    super(props);

    var fullscreen = false;
    if(window.innerWidth > screen.width*0.9 &&
        window.innerHeight > screen.height*0.9
        ){
      fullscreen = true;
    }

    this.state = {
      pageView: 0,
      pageInput: 0,
      pdfPageArray: [],
      pageInfo: [],
      showContent: false,
      showGrid: false,
      showMenu: false,
      showThumbnails: false,
      scale: 1,
      scrollMethod: 0,
      rotate: 0,
      showDouble: false,

      firstLoad: this.props.adjustFull === false? true: false,

      files: getFiles(this.props.files),

      drag: false,
      fullscreen: fullscreen,

      index: this.props.initial !== undefined?this.props.initial:0,

      width: window.innerWidth<1100?window.innerWidth:1100,
      height: window.innerHeight<1086?window.innerHeight:1086,
    }

//    this.toolBarHeight = 32

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.displayWindowSize = this.displayWindowSize.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onPageChange = this.onPageChange.bind(this)

    this.thumbnailContainerRef = React.createRef();
    this.refContent = null;
    this.refPages = React.createRef();
    this.refMain = React.createRef();
    this.refPdfMain = React.createRef();
    this.refPagesArray = [];
    this.pageInfo = []

    this.refPdfjs = React.createRef();

    this.inputTimeout = null;
    this.inputPage = null;
    this.inputPageTimeout = null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.displayWindowSize);
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.displayWindowSize);
    window.removeEventListener('keydown', this.onKeyDown);
    clearTimeout(this.inputTimeout);
    clearTimeout(this.inputPage);
    clearTimeout(this.inputPageTimeout);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var f = getFiles(nextProps.files)

    return {
      files: f,
    }
  }

  onDocumentLoadSuccess(pdf){//{ numPages }
    const { numPages } = pdf

    var pages = [];
    this.refPagesArray = [];
    for(var x=0; x<numPages; x++){
      pages.push(x+1)
      this.refPagesArray.push(React.createRef())
    }

    this.setState({pdfPageArray: pages, pageInfo: []})
    this.pageInfo = []
    if(!this.state.firstLoad){
      this.setState({pageInput: 1, firstLoad: true})
      setTimeout(()=>{
        this.onScaleChange({target: { value: "page-width"}})
      }, 500)
    }
  }

  onPageLoadSuccess(page, viewport){
    if(viewport === undefined) return
    if(this.pageInfo.find(o => o.index === page.pageIndex)) return

    this.pageInfo.push({
      index: page.pageIndex,
      pageWidth: viewport.width,
      pageHeight: viewport.height,
      isLandscape: viewport.width > viewport.height
    })

    clearTimeout(this.inputPageTimeout);
    this.inputPageTimeout = setTimeout(()=>{
      this.setState({ pageInfo: this.pageInfo })
    }, 500)
  }

  onExit(){
    if(this.props.onClose)
      this.props.onClose();
  }

  onKeyDown(event){
    if(event.keyCode === 27) {
      this.onExit()
    }
  }

  goToPage(page){
    //console.log("p",page);
    if(this.state.showContent){
      this.refPagesArray[page-1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }else if(this.state.showGrid){
      this.refPagesArray[page-1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      this.setState({showContent: true, showGrid: false})
    }
  }

  onPageNumChange(event){
    clearTimeout(this.inputPage);
    const { name, value } = event.target;
    this.setState({pageInput: value})
    this.inputPage = setTimeout(() => {
      if(this.refPagesArray[value-1] !== undefined){
        this.refPagesArray[value-1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },1000);
  }

  onInputPageFocusout(){
    // if(this.refPagesArray[this.state.pageInput-1] !== undefined){
    //   this.refPagesArray[this.state.pageInput-1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // }
    // else this.setState({pageInput: this.state.pageView})
  }

  onPage(page){
    this.setState({pageView: page})
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.setState({pageInput: page})
      this.onPageChange(page)
    },1000);
  }

  onPageChange(){}

  onScaleChange(event){
    var value = event.target.value
    let scale = parseFloat(value);

    if (scale > 0) {
      if(scale < 0.25) scale = 0.25;
      else if(scale > 4.0) scale = 4.0;
    }else{
      scale = 1.0;
      var r = this.refPagesArray[this.state.pageInput - 1]
      if(r === undefined) return
      const MAX_AUTO_SCALE = 1.25;

      const noPadding = this.state.fullscreen;
      let hPadding = noPadding ? 0 : 10;
      let vPadding = noPadding ? 0 : 10;

      var pageWidthScale = 0, pageHeightScale = 0
      if(this.refPdfMain.current !== null && this.refPdfMain.current.clientWidth !== null && r.current.clientWidth !== null)
        pageWidthScale = ((this.refPdfMain.current.clientWidth - hPadding) / r.current.clientWidth) *
          this.state.scale;
      if(this.refPdfMain.current !== null && this.refPdfMain.current.clientHeight !== null && r.current.clientHeight !== null)
        pageHeightScale =
          ((this.refPdfMain.current.clientHeight - vPadding) / r.current.clientHeight) *
          this.state.scale;

      function isPortraitOrientation(size) {
        return size.width <= size.height;
      }

      switch (value) {
        case "page-actual":
          scale = 1;
          break;
        case "page-width":
          scale = pageWidthScale;
          break;
        case "page-height":
          scale = pageHeightScale;
          break;
        case "page-fit":
          scale = Math.min(pageWidthScale, pageHeightScale);
          break;
        case "auto":
          // For pages in landscape mode, fit the page height to the viewer
          // *unless* the page would thus become too wide to fit horizontally.
          const horizontalScale = isPortraitOrientation({width: r.current.clientWidth, height: r.current.clientHeight})
            ? pageWidthScale
            : Math.min(pageHeightScale, pageWidthScale);
          scale = Math.min(MAX_AUTO_SCALE, horizontalScale);
          break;
        default:
          return;
      }
    }
    this.setState({scale: scale})
  }

  onDocChange(){
    var index = event.target.value
    if(this.state.files[index].file === null && this.props.onRetrive)
      this.props.onRetrive(this.state.files[index].id)
    this.setState({index})
  }

  onMinus(){
    if(this.state.scale <= 0.25) return;
    this.setState({scale: this.state.scale - 0.25})
  }

  onPlus(){
    if(this.state.scale >= 4.0) return;
    this.setState({scale: this.state.scale + 0.25})
  }

  onShowContent(){
    this.setState({showContent: !this.state.showContent, showGrid: false})
  }

  onShowGrid(){
    this.setState({showGrid: !this.state.showGrid, showContent: false})
  }

  onMenu(e){
    e.stopPropagation();
    this.setState({showMenu: !this.state.showMenu})
  }

  onNxtPage(){
    var n = this.state.pageView;
    if(n >= this.refPagesArray.length) n = this.refPagesArray.length - 1;
    this.refPagesArray[n].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  onPrvPage(){
    var n = this.state.pageView - 2;
    if(n < 0) n = 0;
    this.refPagesArray[n].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  onGoFirst(){
    this.refPagesArray[0].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  onGoLast(){
    if(this.refPagesArray.length < 1) return;
    this.refPagesArray[this.refPagesArray.length-1].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  clear(){
    if(this.state.showMenu){
      this.setState({showMenu: false})
    }

    if(this.props.naked === true || this.props.embeded === true)
      if(this.props.onClose)
        this.props.onClose();
  }

  onLayout(type){
    this.setState({scrollMethod: type});
  }

  getLayout(option){
    let css = "pdfvertical"
    if(this.state.scrollMethod === 1) css = "pdfhorizontal"
    if(this.state.scrollMethod === 2) css = "pdfwrap"

    if(option.links === false){
      css += " pdfBlockLinks"
    }
    return css
  }

  getTopLayout(){
    if(this.state.scrollMethod === 1) return "boardpanel"
    if(this.state.scrollMethod === 2) return "boardpanel centerFlex"
    return "boardpanel centerFlex"
  }

  onRotAntiClock(){
    var a = this.state.rotate - 90;
    if(a < 0) a += 360;
    this.setState({rotate: a})
  }

  onRotClock(){
    var a = this.state.rotate + 90;
    if(a > 359) a -= 360;
    this.setState({rotate: a})
  }

  onDrag(s){
    this.setState({drag: s})
  }

  getHiddenOption(){
    switch(this.state.scale){
      case 0:
      case 0.5:
      case 0.75:
      case 1.0:
      case 1.25:
      case 1.5:
      case 2.0:
      case 3.0:
      case 4.0:
        return null;
      default:
        return (
          <option value={this.state.scale} style={{display: 'none'}}>{this.state.scale*100}%</option>
        )
    }
  }

  onMove(){
    this.refPages.current.scrollTop = 100;
  }

  getContentWidth(){
    if(this.state.showContent) return 200;
    if(this.state.showGrid) return this.state.width;
    return 0;
  }

  getMainWidth(){
    if(this.state.showContent) return this.state.width-200;
    if(this.state.showGrid) return 0;
    return this.state.width;
  }

  displayWindowSize(){
    if(this.state.fullscreen){
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }else{
      this.setState({
        width: window.innerWidth<1100?window.innerWidth:1100,
        height: window.innerHeight<1086?window.innerHeight:1086,
      });
    }
  }

  onFullScreen() {
    try {
      if (this.state.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
      } else {
        var elem = this.refMain.current;//document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }

      }
    } catch { }
    this.setState({ fullscreen: !this.state.fullscreen })
  }

  getSelectList(){
    var array = [];
    this.state.files.forEach(function(file){
      var results = "";
      if(file.verify === false){
        results = "(rejected)"
      }else if(file.verify === true){
        results = "(verified)"
      }
      array.push(
        <option key={array.length} value={array.length}>
          {file.positionString !== undefined &&
            file.positionString+" - "
          }
          {file.title} {results}</option>
      )
    })

    return array;
  }

  onVerify(){
    var files = this.state.files;
    files[this.state.index].verify = true;

    var completed = true;
    files.forEach(function(f){
      if(f.verify === null) completed = false;
    })

    this.setState({files: files});
    var id = this.state.index;
    if(files[this.state.index].id !== undefined)
      id = files[this.state.index].id
    this.props.onVerify(id, true);

    if(completed && this.props.onClose !== undefined){
      this.props.onClose();
    }
  }

  onVerifyFailed(){
    var files = this.state.files;
    files[this.state.index].verify = false;

    var completed = true;
    files.forEach(function(f){
      if(f.verify === null) completed = false;
    })

    this.setState({files: files});
    var id = this.state.index;
    if(files[this.state.index].id !== undefined)
      id = files[this.state.index].id
    this.props.onVerify(id, false);

    if(completed && this.props.onClose !== undefined){
      this.props.onClose();
    }
  }

  getVerifiedCount(){
    var need = 0;
    this.state.files.forEach(function(f){
      if(f.verify === null) need++;
    })
    if(need > 0)
      return need + " Document Left"
    return "All completed";
  }

  onScrollChange(num){
    if(this.props.onDocPixel !== undefined)
      this.props.onDocPixel(num)
  }

  getPageWidth(){
    if(this.refPages !== null && this.refPages.current !== null)
      return this.refPages.current.clientWidth
    return 300
  }

  onPdfWindow(e){
    e.stopPropagation();
    if(this.state.showMenu){
      this.setState({showMenu: false})
    }
  }

  onPrint(){
//    window.print();  SimplePrint
    let data = this.state.files[this.state.index].file;//new window.Blob(this.state.files[this.state.index].file, { type: 'application/pdf' })
    let localPdf = new window.Blob([data], { type: 'application/pdf' })
    var p = URL.createObjectURL(localPdf)
    import("./printSimple").then(simplePrint => {
      simplePrint.default(p)
    })
//    SimplePrint(localPdf)
  }

  onPrev(){
    var {index} = this.state
    index--
    if(index < 0) index = 0
    if(this.state.files[index].file === null && this.props.onRetrive)
      this.props.onRetrive(this.state.files[index].id)
    this.setState({index})
    if(this.props.updateSelectedItemId && this.state.files[index]){
      this.props.updateSelectedItemId(this.state.files[index].id);
    }
  }

  onNext(){
    var {index} = this.state
    index++
    if(index >= this.state.files.length) index = this.state.files.length - 1
    if(this.state.files[index].file === null && this.props.onRetrive)
      this.props.onRetrive(this.state.files[index].id)
    this.setState({index});
    if(this.props.updateSelectedItemId && this.state.files[index]){
      this.props.updateSelectedItemId(this.state.files[index].id);
    }
  }

  getId(){
    if(this.state.index < this.state.files.length && this.state.files[this.state.index] !== undefined)
      if(this.state.files[this.state.index].id !== undefined && this.state.files[this.state.index].id !== "") return "id-"+this.state.files[this.state.index].id
      else if(this.state.files[this.state.index].documentId !== undefined && this.state.files[this.state.index].documentId !== "") return "id-"+this.state.files[this.state.index].documentId
    return ""
  }

  renderThumbnails(){
    return null
  }

  renderMenu(){
    var tSelection = "", tHand = "", vScoll="", hScroll = "", wScroll = ""
    if(this.state.drag) tHand = " toggled"
    else tSelection = " toggled"
    if(this.state.scrollMethod===0) vScoll=" toggled"
    else if(this.state.scrollMethod===1) hScroll=" toggled"
    else if(this.state.scrollMethod===2) wScroll=" toggled"
    return (
      <div id="secondaryToolbar" className="secondaryToolbar doorHangerRight">
        <div id="secondaryToolbarButtonContainer">
          <button id="secondaryPresentationMode" className="secondaryToolbarButton presentationMode visibleLargeView" title="Switch to Presentation Mode" tabIndex="51">
            <span>Presentation Mode</span>
          </button>
          {/*<button id="secondaryOpenFile" className="secondaryToolbarButton openFile visibleLargeView" title="Open File" tabIndex="52">
            <span>Open</span>
          </button>*/}
          {typeof InstallTrigger === 'undefined' &&
            <button id="secondaryPrint" className="secondaryToolbarButton print visibleMediumView" title="Print" tabIndex="53" onClick={this.onPrint.bind(this)}>
              <span>Print</span>
            </button>
          }
          {/*<button id="secondaryDownload" className="secondaryToolbarButton download visibleMediumView" title="Download" tabIndex="54">
            <span>Download</span>
          </button>*/}
          <a href="#" id="secondaryViewBookmark" className="secondaryToolbarButton bookmark visibleSmallView" title="Current view (copy or open in new window)" tabIndex="55">
            <span>Current View</span>
          </a>
          <div className="horizontalToolbarSeparator visibleLargeView"></div>

          <button id="firstPage" className="secondaryToolbarButton firstPage" title="Go to First Page" tabIndex="56" onClick={this.onGoFirst.bind(this)}>
            <span>Go to First Page</span>
          </button>
          <button id="lastPage" className="secondaryToolbarButton lastPage" title="Go to Last Page" tabIndex="57" onClick={this.onGoLast.bind(this)}>
            <span>Go to Last Page</span>
          </button>

          <div className="horizontalToolbarSeparator"></div>

          <button id="pageRotateCw" className="secondaryToolbarButton rotateCw" title="Rotate Clockwise" tabIndex="58" onClick={this.onRotAntiClock.bind(this)}>
            <span>Rotate Clockwise</span>
          </button>
          <button id="pageRotateCcw" className="secondaryToolbarButton rotateCcw" title="Rotate Counterclockwise" tabIndex="59" onClick={this.onRotClock.bind(this)}>
            <span>Rotate Counterclockwise</span>
          </button>

          <div className="horizontalToolbarSeparator"></div>

          <button id="cursorSelectTool" className={"secondaryToolbarButton selectTool"+tSelection} title="Enable Text Selection Tool" tabIndex="60" onClick={this.onDrag.bind(this, false)}>
            <span>Text Selection Tool</span>
          </button>
          <button id="cursorHandTool" className={"secondaryToolbarButton handTool"+tHand} title="Enable Hand Tool" tabIndex="61" onClick={this.onDrag.bind(this, true)}>
            <span>Hand Tool</span>
          </button>

          <div className="horizontalToolbarSeparator"></div>

          <button id="scrollVertical" className={"secondaryToolbarButton scrollModeButtons scrollVertical"+vScoll} title="Use Vertical Scrolling" tabIndex="62" onClick={this.onLayout.bind(this, 0)}>
            <span>Vertical Scrolling</span>
          </button>
          <button id="scrollHorizontal" className={"secondaryToolbarButton scrollModeButtons scrollHorizontal"+hScroll} title="Use Horizontal Scrolling" tabIndex="63" onClick={this.onLayout.bind(this, 1)}>
            <span>Horizontal Scrolling</span>
          </button>
          <button id="scrollWrapped" className={"secondaryToolbarButton scrollModeButtons scrollWrapped"+wScroll} title="Use Wrapped Scrolling" tabIndex="64" onClick={this.onLayout.bind(this, 2)}>
            <span>Wrapped Scrolling</span>
          </button>

          {/*}<div className="horizontalToolbarSeparator scrollModeButtons"></div>

          <button id="spreadNone" className="secondaryToolbarButton spreadModeButtons spreadNone toggled" title="Do not join page spreads" tabIndex="65">
            <span>No Spreads</span>
          </button>
          <button id="spreadOdd" className="secondaryToolbarButton spreadModeButtons spreadOdd" title="Join page spreads starting with odd-numbered pages" tabIndex="66">
            <span>Odd Spreads</span>
          </button>
          <button id="spreadEven" className="secondaryToolbarButton spreadModeButtons spreadEven" title="Join page spreads starting with even-numbered pages" tabIndex="67">
            <span>Even Spreads</span>
          </button>

          <div className="horizontalToolbarSeparator spreadModeButtons"></div>

          <button id="documentProperties" className="secondaryToolbarButton documentProperties" title="Document Properties…" tabIndex="68">
            <span>Document Properties…</span>
          </button>*/}
        </div>
      </div>
    )
  }

  renderToolBar(display = null){
    return (
      <div className="Toolbar">
        <div id="toolbarContainer">
          <div id="toolbarViewer">
            <div id="toolbarViewerLeft">
              <button id="sidebarToggle" className="toolbarButton" title="Toggle Sidebar" tabIndex="11" onClick={this.onShowContent.bind(this)}>
                <span>Toggle Sidebar</span>
              </button>
              {/*this.state.showGrid ?
                <SingleSideIcon size={22} style={{marginRight: 10}} color='#999999' onClick={this.onShowGrid.bind(this)}/>
                :
                <SideIcon size={22} style={{marginRight: 10}} color='#999999' onClick={this.onShowGrid.bind(this)}/>
              */}

              <div className="toolbarButtonSpacer"></div>
              {/*<button id="viewFind" className="toolbarButton" title="Find in Document" tabIndex="12">
                <span>Find</span>
              </button>*/}
              <div className="splitToolbarButton hiddenSmallView">
                <button className="toolbarButton pageUp" title="Previous Page" id="previous" tabIndex="13" onClick={this.onPrvPage.bind(this)}>
                  <span>Previous</span>
                </button>
                <div className="splitToolbarButtonSeparator"></div>
                <button className="toolbarButton pageDown" title="Next Page" id="next" tabIndex="14" onClick={this.onNxtPage.bind(this)}>
                  <span>Next</span>
                </button>
              </div>
              <input
                type="number"
                id="gotoPage"
                className="toolbarField pageNumber"
                title="Page"
                value="1"
                size="4"
                min="1"
                max={this.state.pdfPageArray.length}
                value={this.state.pageInput}
                onChange={this.onPageNumChange.bind(this)}
                onBlur={this.onInputPageFocusout.bind(this)}
                onKeyPress={this.onInputPageFocusout.bind(this)}
                tabIndex="15"
                />
              <span id="numPages" className="toolbarLabel">of {this.state.pdfPageArray.length}</span>
            </div>
            <div id="toolbarViewerMiddle">
              <div className="splitToolbarButton">
                <button id="zoomOut" className="toolbarButton zoomOut" title="Zoom Out" tabIndex="21" onClick={this.onMinus.bind(this)}>
                  <span>Zoom Out</span>
                </button>
                <div className="splitToolbarButtonSeparator"></div>
                <button id="zoomIn" className="toolbarButton zoomIn" title="Zoom In" tabIndex="22"  onClick={this.onPlus.bind(this)}>
                  <span>Zoom In</span>
                 </button>
              </div>
              <span id="scaleSelectContainer" className="dropdownToolbarButton">
                <select id="scaleSelect" title="Zoom" tabIndex="23"
                  onChange={this.onScaleChange.bind(this)}
                  value={this.state.scale}
                  >
                  <option id="pageAutoOption" title="" value="auto">Automatic Zoom</option>
                  <option id="pageActualOption" title="" value="page-actual">Actual Size</option>
                  <option id="pageFitOption" title="" value="page-fit">Page Fit</option>
                  <option id="pageWidthOption" title="" value="page-width">Page Width</option>
                  <option title="" value="0.5">50%</option>
                  <option title="" value="0.75">75%</option>
                  <option title="" value="1">100%</option>
                  <option title="" value="1.25">125%</option>
                  <option title="" value="1.5">150%</option>
                  <option title="" value="2">200%</option>
                  <option title="" value="3">300%</option>
                  <option title="" value="4">400%</option>
                </select>
              </span>
                {display !== null &&
                  <div style={{marginLeft: 20}}>
                    {display}
                  </div>
                }
            </div>
            <div id="toolbarViewerRight">
              {!this.state.fullscreen ?
                <button id="presentationMode" className="toolbarButton presentationMode hiddenLargeView" title="Switch to Presentation Mode" tabIndex="31" onClick={this.onFullScreen.bind(this)}>
                  <span>Presentation Mode</span>
                </button>
                :
                <ExitFullScreenIcon id="presentationMode" className="toolbarButton hiddenLargeView" title="Switch to Normal Mode" style={{height: 21}} onClick={this.onFullScreen.bind(this)}>
                  <span>Normal Mode</span>
                </ExitFullScreenIcon>
              }
              {typeof InstallTrigger === 'undefined' &&
                <button id="print" className="toolbarButton print hiddenMediumView" title="Print" tabIndex="33" onClick={this.onPrint.bind(this)}>
                  <span>Print</span>
                </button>
              }
              <div className="verticalToolbarSeparator hiddenSmallView"></div>

              {this.props.onClose !== undefined && this.props.fullscreen === true &&
                <ExitIcon id="exitMode" className="toolbarButton hiddenLargeView" title="Exit PDF Viewer" tabIndex="37" style={{height: 21}} onClick={this.onExit.bind(this)}>
                  <span>Exit PDF Viewer</span>
                </ExitIcon>
              }

              <button id="secondaryToolbarToggle" className="toolbarButton" title="Tools" tabIndex="36" onClick={this.onMenu.bind(this)}>
                <span>Tools</span>
              </button>
            </div>
          </div>
          {/*<div id="loadingBar" className="hidden" style="width: calc(100% - 16px);">
            <div className="progress" style="height: 100%; width: 100%;">
              <div className="glimmer">
              </div>
            </div>
          </div>*/}
          {this.state.showMenu &&
            <div>
              {this.renderMenu()}
            </div>
          }
        </div>
        <div id="printServiceOverlay" className="container hidden">
          <div className="dialog">
            <div className="row">
              <span>Preparing document for printing…</span>
            </div>
            <div className="row">
              <progress value="0" max="100"></progress>
              <span className="relative-progress">0%</span>
            </div>
            <div className="buttonRow">
              <button id="printCancel" className="overlayButton"><span>Cancel</span></button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  displayPage (page, index, key, option) {
    return <PDFPage
      key={key+page}
      forwardRef={this.refPagesArray[index]}
      index={page}
      isShowingThumbnail={this.state.showContent || this.state.showGrid}
      pageNumber={page}
      //triggerOnce={true}
      className="pdfPage fs-exclude"
      naked={option.fullscreen}
      lastPage={index === this.state.pdfPageArray.length-1?true:false}
      root={this.refPages}
      defaultWidth={595}
      defaultHeight={this.state.rotate===90||this.state.rotate===270?420:842}
      scale={this.state.scale}
      rotate={this.state.rotate}
      onPageView={this.onPage.bind(this)}
      onPageloaded={this.onPageLoadSuccess.bind(this)}
      />
  }

  renderDouble(option){
    const { pdfPageArray } = this.state

    if (pdfPageArray.length === 0) return null;

    const firstPage = pdfPageArray[0]
    let list = [
      this.displayPage(firstPage, 0, "r", option)
    ];

    if (pdfPageArray.length < 2) { return list; }

    for (var x = 1; x < pdfPageArray.length; x += 2) {
      var pages = [this.displayPage(pdfPageArray[x], x, "r", option)];
      if (x + 1 < pdfPageArray.length) {
        pages.push(this.displayPage(pdfPageArray[x + 1], x + 1, "r", option));
      }
      list.push(
        <div className="pdfDouble" key={"p" + x}>
          {pages}
        </div>
      );
    }

    return list;
  }

  renderSingle(option) {
    return this.state.pdfPageArray.map((page, index) =>
      this.displayPage(page, index, "s", option)
    );
  }

  renderPdf(option = {}){
//    {width: this.getContentWidth(), height: this.state.height - this.toolBarHeight}
//style={panel}
    var css = 'pdfBox', style = {}, pdfcss = "react-pdf__Document"
    if(option.fullscreen === true || this.state.fullscreen) css = 'pdfFullScreen'
    if(option.embedded === true){
      css = 'pdfEmbedded'
      style.height = this.state.height
    }else if(option.cssTop !== undefined && option.cssTop !== ''){
      css = option.cssTop
    }else if(option.style !== undefined){
      style = option.style
    }

    if(this.state.scrollMethod === 1) pdfcss = "react-pdf__Document hoz"

    try {
      style.height = document.getElementById("minute-new-page-pdf-container").clientHeight;
    } catch { }

    var previewParentHeight = 'auto';
    var previewParentWidth = 'auto';
    var previewParentContainerWidth = 'auto';
    try {
      previewParentHeight = document.getElementsByClassName("pdf-scroll-container")[0].clientHeight + "px";
      previewParentWidth = '300px';
      previewParentContainerWidth = '330px';
      previewParentHeight = document.getElementsByClassName("react-pdf__Document")[0].clientHeight + "px";
      // previewParentWidth = document.getElementsByClassName("pdf-scroll-container")[0].clientWidth;
      // previewParentWidth = Math.floor(previewParentWidth * 0.3) < 300 ? 300 : previewParentWidth;
      // previewParentWidth = previewParentWidth + "px";
    } catch {}
    
    return (
      <div className={css} style={style} onClick={this.onPdfWindow.bind(this)}>
        {option.boxHeader === true &&
          <div className="pdfBoxHead">
            <div data-sl="mask" className="boardpanel fs-exclude" id={this.getId()}>
              {this.state.files.length === 1 ?
                <label>{this.state.files[0].title}</label>
                :
                <select
                  onChange={this.onDocChange.bind(this)}
                  value={this.state.index}
                  className="pdfZoomSel"
                  >
                  {this.getSelectList()}
                </select>
              }
            </div>
            <div className="boardpanel centerpanel">
              {this.state.files.length > 1 &&
                <div className="boardpanel" style={{marginRight: 10}}>
                  <Tooltip title="View the previous document">
                    <div>
                      <IconButton disabled={this.state.index === 0} onClick={this.onPrev.bind(this)}>
                        <LeftIcon color={this.state.index === 0 ? "#d4d4d4" : "#4680ff"}/>
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title="View the next document">
                    <div>
                      <IconButton disabled={this.state.index === (this.state.files.length - 1)} onClick={this.onNext.bind(this)}>
                        <RightIcon color={this.state.index === (this.state.files.length - 1) ? "#d4d4d4" : "#4680ff"}/>
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              }
              {this.props.onVerify !== undefined &&
                <div className="boardpanel">
                  {(this.state.files[this.state.index].verify === null || this.state.files[this.state.index].verify === true) &&
                    <Tooltip title="Accept document changes">
                      <div>
                        <MuiButton variant="contained" style={{ marginLeft: 20 }} onClick={this.onVerify.bind(this)}>Accept</MuiButton>
                      </div>
                    </Tooltip>
                  }
                  {(this.state.files[this.state.index].verify === null || this.state.files[this.state.index].verify === false) &&
                    <Tooltip title="Reject the document">
                      <div>
                        <MuiButton variant="outlined" style={{ marginLeft: 20 }} onClick={this.onVerifyFailed.bind(this)}>Reject</MuiButton>
                      </div>
                    </Tooltip>
                  }
                </div>
              }
            </div>
          </div>
        }
        {option.showToolBar !== false &&
          this.renderToolBar()
        }
        <ScrollContainer
          forwardRef={this.refPages}
          className={pdfcss} //{`${naked !== true?'pdfPanel':'pdfNaked'}`}
          drag={this.state.drag}
          onScrollChange={this.onScrollChange.bind(this)}
          loadInitialSpot={this.props.loadInitialSpot}
          >
          <div ref={this.thumbnailContainerRef} style={{ position: 'relative', height: previewParentHeight, overflow: 'hidden' }}></div>
          <PDFDoc
            forwardRef={this.refPdfjs}
            item={this.state.files[this.state.index]}
            onLoadSuccess={this.onDocumentLoadSuccess}
            height={style}

            {...this.props}
          >
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, position: 'relative' }}>
              {(this.state.showContent || this.state.showGrid)
                // &&this.thumbnailContainerRef && this.thumbnailContainerRef.current
                ?
                <Portal container={this.thumbnailContainerRef.current}>
                  <div className="pdfContent" style={{ display: 'flex', height: style.height, width: previewParentContainerWidth, background: 'rgba(64, 64, 64, 1)', position: 'sticky', left: 0, top: 0, height: previewParentHeight, overflow: 'auto' }}>
                    <div className="pdfContentHead">
                      <div className="boardpanel">
                      </div>
                    </div>
                    <div style={{ flex: 1, width: previewParentContainerWidth }} className={`pdfContentScroll ${this.state.showGrid ? "pdfContentExp" : ""}`} ref={ref => (this.refContent = ref)}>
                      {this.state.pdfPageArray.map((page, index) => (
                        <div key={"s" + page} style={{ flex: 1, display: 'flex' }} data-sl="mask">
                          <PDFPage
                            forwardRef={React.createRef()}
                            width={previewParentWidth}
                            index={"s" + page}
                            isPreviewPage={true}
                            pageNumber={page}
                            className="pdfPage link fs-exclude"
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            renderInteractiveForms={false}
                            naked={true}
                            hover={true}
                            lastPage={index === this.state.pdfPageArray.length - 1 ? true : false}
                            select={this.state.pageView === page ? true : false}
                            //triggerOnce={true}
                            onClick={this.goToPage.bind(this, page)}
                            root={this.refContent}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Portal>
                : null
              }
            <div className={this.getTopLayout()} style={{ position: 'relative', flex: 1 }} ref={this.refPdfMain}>
              {this.state.showThumbnails &&
                this.renderThumbnails()
              }
              {/* {(this.state.showContent || this.state.showGrid)
              // &&this.thumbnailContainerRef && this.thumbnailContainerRef.current
               ?
                // <Portal container={this.thumbnailContainerRef.current}>
                  <div className="pdfContent" style={{ display: 'flex', height: style.height, width: previewParentContainerWidth, padding: '15px', background: 'rgba(64, 64, 64, 1)', position: 'sticky', left: 0, top: 0, height: style.height }}>
                    <div className="pdfContentHead">
                      <div className="boardpanel">
                      </div>
                    </div>
                    <div style={{ flex: 1, width: previewParentWidth }} className={`pdfContentScroll ${this.state.showGrid ? "pdfContentExp" : ""}`} ref={ref => (this.refContent = ref)}>
                      {this.state.pdfPageArray.map((page, index) => (
                        <div key={"s" + page} style={{ flex: 1, display: 'flex' }}>
                          <PDFPage
                            forwardRef={React.createRef()}
                            width={previewParentWidth}
                            index={"s" + page}
                            isPreviewPage={true}
                            pageNumber={page}
                            className="pdfPage link fs-exclude"
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            renderInteractiveForms={false}
                            naked={true}
                            hover={true}
                            lastPage={index === this.state.pdfPageArray.length - 1 ? true : false}
                            select={this.state.pageView === page ? true : false}
                            //triggerOnce={true}
                            onClick={this.goToPage.bind(this, page)}
                            root={this.refContent}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                // </Portal> 
                : null
              } */}
              {/* {(this.state.showContent || this.state.showGrid) ? <div style={{ width: '300px' }}></div> : null} */}
              <div
                className={`${this.getLayout(option)}`}
                //forwardRef={this.refPages}
                >
                {this.state.showDouble?
                  this.renderDouble(option)
                  :
                  this.renderSingle(option)
                }
              </div>
            </div>
            </div>

          </PDFDoc>
        </ScrollContainer>
        {option.boxFooter === true &&
          <div className="pdfBoxFoot">
            <div>
              {this.props.onVerify !== undefined &&
                <label className="pdfVerifyLabel">{this.getVerifiedCount()}</label>
              }
            </div>
            <div className="boardpanel centerpanel">
              {this.props.button !== undefined && this.props.button.export === true &&
                <Tooltip title="Save file as MS Document">
                  <div>
                    <MuiButton variant="outlined" style={{ marginRight: 10 }} onClick={this.props.onExport.bind(this)}>Export</MuiButton>
                  </div>
                </Tooltip>
              }
              {this.props.button !== undefined && this.props.button.download === true &&
                <Tooltip title="Save file as pdf">
                  <div>
                    <MuiButton variant="outlined" style={{ marginRight: 10 }} onClick={this.props.onDownload.bind(this)}>Download</MuiButton>
                  </div>
                </Tooltip>
              }
              <MuiButton variant="contained" onClick={this.onExit.bind(this)}>Close</MuiButton>
            </div>
          </div>
        }
      </div>
    )
  }

  render(){
    var option = {
      fullscreen: false,
      showToolBar: true,
      embedded: false,

      boxHeader: true,
      boxFooter: true,
    }
    var { fullscreen, showToolBar, embedded } = this.props
    if(fullscreen === true){
      option.fullscreen = true
      option.boxHeader = false
      option.boxFooter = false
    }
    if(showToolBar !== undefined){
      option.showToolBar = showToolBar
    }
    if(embedded === true){
      option.embedded = true
      option.boxHeader = false
      option.boxFooter = false
    }
    return (
      <div className={`${option.embedded?"center":"aBoxTop-overlay"}`} onClick={this.clear.bind(this)} ref={this.refMain}>
        {this.renderPdf(option)}
      </div>
    )
  }
}

export class PDFAnnotation extends PDFBase {
  constructor(props) {
    super(props);

    this.state.drawMenu = false
    this.state.textMenu = false
    this.state.signBox = false
    this.state.color = '#2483c7'
    this.state.stroke = 1
    this.state.strokeShadow = 0
    this.state.font_size = 16
    this.state.font_color = '#2483c7'
    this.state.drawText = false

    //this.state.pdfFactory = null
    this.fabricObjects = []
    this.fabricObjectsData = []
    //this.active_canvas = 0;
    this.pages_rendered = 0;


    this.drawCol = ['#f82500','#f39600','#ffff00','#6db000','#2483c7','#ff00ff','#ff7293','#ff8c5e','#fcee7c','#a1fa7b','#8db9ff','#f587ff',
        '#ffffff','#c0c0c0','#808080','#404040','#999999','#000000']
  }

  onPageLoadSuccess(page, viewport){
    super.onPageLoadSuccess(page, viewport)
    if(viewport === undefined)
      viewport = page.getViewport({scale: this.state.scale});
    var canvas = document.createElement('canvas');

    var index = page.pageIndex

    //var f = this.refPagesArray[page.pageIndex]
    var s = '[data-page-number="'+(page.pageIndex+1)+'"]'
    var c = document.querySelectorAll(s)[0]
    if(c === undefined) return
    c.appendChild(canvas);

    canvas.id = 'anna'+(page.pageIndex+1);
    canvas.className = 'pdf-canvas';
    canvas.height = viewport.height; //1372;//
    canvas.width = viewport.width; //970;//

    var fabricObj = new fabric.Canvas(canvas.id, {
      freeDrawingBrush: {
          width: this.state.stroke,
          color: this.state.color
      }
    });
    this.fabricObjects.push(fabricObj);
    this.fabricObjectsData.push({})

    fabricObj.on('object:added', () => {
      var oldValue = Object.assign({}, this.fabricObjectsData[index]);
			this.fabricObjectsData[index] = fabricObj.toJSON()
      console.log(index + 1, oldValue, this.fabricObjectsData[index]);
		})

    fabricObj.on('after:render', () => {
			this.fabricObjectsData[index] = fabricObj.toJSON()
			fabricObj.off('after:render')
		})

    fabricObj.upperCanvasEl.onclick = () => {
      this.fabricClickHandler(event, fabricObj);
    }

    // var rect = new fabric.Rect({
    //     top : 100,
    //     left : 100,
    //     width : 60,
    //     height : 70,
    //     fill : 'red'
    // });
    // fabricObj.add(rect);
  }

  onDocumentLoadSuccess(pdf){//{ numPages }
    super.onDocumentLoadSuccess(pdf)
return
    const { numPages } = pdf
    //AnnotationFactory
    // var p = pdf.getData().then((data) => {
    //   let pdfFactory = new AnnotationFactory(data)
    //   this.setState({pdfFactory})
    // })

    for(var x=0; x<numPages; x++){
      //Fabricjs
      var pages_rendered = 0
      var scale = 1.3
      pdf.getPage(x+1).then((page) => {
        var viewport = page.getViewport(scale, 0);
        var canvas = document.createElement('canvas');

        var s = '[data-page-number="'+(page.pageIndex+1)+'"]'
        var c = document.querySelectorAll(s)[0]
        if(c === undefined) return
        c.appendChild(canvas);
        canvas.className = 'pdf-canvas';
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var context = canvas.getContext('2d');

        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);
        renderTask.then(() => {
          $('.pdf-canvas').each(function (index, el) {
            $(el).attr('id', 'page-' + (index + 1) + '-canvas');
          });
          pages_rendered++;
        //  if (pages_rendered == numPages) this.initFabric();
        });
      });
    }
  }

  initFabric() {
    console.log('initFabric')
		var inst = this;
    $('#pdf-container canvas').each(function (index, el) {
      var background = el.toDataURL("image/png");
      var fabricObj = new fabric.Canvas(el.id, {
        freeDrawingBrush: {
            width: 1,
            color: inst.color
        }
      });
			inst.fabricObjects.push(fabricObj);
			if (typeof options.onPageUpdated == 'function') {
				fabricObj.on('object:added', function() {
					var oldValue = Object.assign({}, inst.fabricObjectsData[index]);
					inst.fabricObjectsData[index] = fabricObj.toJSON()
					options.onPageUpdated(index + 1, oldValue, inst.fabricObjectsData[index])
				})
			}
	    fabricObj.setBackgroundImage(background, fabricObj.renderAll.bind(fabricObj));
	      $(fabricObj.upperCanvasEl).click(function (event) {
          inst.active_canvas = index;
          //inst.fabricClickHandler(event, fabricObj);
			  });
			fabricObj.on('after:render', function () {
				inst.fabricObjectsData[index] = fabricObj.toJSON()
				fabricObj.off('after:render')
			})
		});
	}

	fabricClickHandler(event, fabricObj) {
    if (this.state.drawText) {
      var text = new fabric.IText('Sample text', {
        left: event.clientX - fabricObj.upperCanvasEl.getBoundingClientRect().left,
        top: event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
        fill: this.state.font_color,
        fontSize: this.state.font_size,
        selectable: true
      });
      fabricObj.add(text);
      this.setState({drawText: false})
    }
	}

  onAnnaSign(){
    this.setState({signBox: !this.state.signBox})
  }

  onAnnaPen(){
    this.fabricObjects.forEach(fb => {
      fb.isDrawingMode = true;
    })
  }

  onAnnaPenOff(){
    this.fabricObjects.forEach(fb => {
      fb.isDrawingMode = false;
    })
  }

  onAnnaText(){
    this.fabricObjects.forEach(fabricObj => {
	     fabricObj.isDrawingMode = false;
	  });
    this.setState({drawText: true})
  }

  onDrawMenu(e){
    e.stopPropagation();
    this.setState({drawMenu: !this.state.drawMenu})
  }

  onTextMenu(e){
    e.stopPropagation();
    this.onAnnaPenOff()
    this.setState({textMenu: !this.state.textMenu})
  }

  onColourSel(col){
    this.setState({color: col, drawMenu: false})
    this.fabricObjects.forEach((fabricObj) => {
      fabricObj.freeDrawingBrush.color = col;
      fabricObj.isDrawingMode = true;
      if(this.state.strokeShadow > 0)
        this.setShadowWidth(fabricObj, this.state.strokeShadow, col)
    });
  }

  onFontColourSel(col){
    this.setState({font_color: col, textMenu: false, drawText: true})
  }

  setShadowWidth(fabricObj, size, colour){
    var v = size;
    if(v === undefined) v = this.state.strokeShadow
    var c = colour
    if(c === undefined) c = this.state.color
    if(v > 0){
      fabricObj.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: v,
        offsetX: 0,
        offsetY: 0,
        affectStroke: true,
        color: c,
      });
    }
  }

  onStroke(e){
    e.stopPropagation();
    const {name, value} = e.target
    var v = parseInt(value) || 1
    this.setState({stroke: parseInt(v)})
    this.fabricObjects.forEach((fabricObj) => {
	    fabricObj.freeDrawingBrush.width = v;
      this.setShadowWidth(fabricObj)
	  });
  }

  onShadow(e){
    e.stopPropagation();
    const {name, value} = e.target
    var v = parseInt(value) || 1
    this.setState({strokeShadow: v})
    if(v > 0){
      this.fabricObjects.forEach((fabricObj) => {
  	    this.setShadowWidth(fabricObj, v)
  	  });
    }
  }

  menuClick(e){
    e.stopPropagation();
  }

  onTrash(){
    var fabricObj = this.fabricObjects[this.state.pageView-1]
    fabricObj.clear();
    return
    var activeObject = this.fabricObjects[this.state.pageView-1].getActiveObject();
  	if (activeObject)
  	{
  	    this.fabricObjects[this.state.pageView-1].remove(activeObject);
  	}
  }

  setFontSize(size){
    this.setState({font_size: size})
  }

  onFonts(e){
    e.stopPropagation();
    const {name, value} = e.target
    this.setState({font_size: value})
  }

  onPdfWindow(e){
    super.onPdfWindow(e)
    this.setState({drawMenu: false, textMenu: false})
  }

  renderDrawMenu(){
    const size = 6;
    const res = this.drawCol.reduce((acc, curr, i) => {
      if ( !(i % size)  ) {    // if index is 0 or can be divided by the `size`...
        acc.push(this.drawCol.slice(i, i + size));   // ..push a chunk of the original array to the accumulator
      }
      return acc;
    }, []);

    return (
      <div id="drawToolbar" className="secondaryToolbar drawHangerRight" onClick={this.menuClick.bind(this)}>
        <div className="drawToolbarButtonContainer">
          <div>
            <label className="colorWhite bold" style={{marginBottom: 5}}>Colours</label>
            <div>
              {res.map((row, index) => {
                return (
                  <div className="pdfdotRow" key={"r"+index}>
                    {row.map((item, index) => {
                        var c = this.state.color === item?'pdfdotSel':''
                        return (
                          <div key={"d"+index} className={c}><span className="pdfdot" style={{backgroundColor: item}} onClick={this.onColourSel.bind(this,item)}></span></div>
                        )
                      })
                    }
                  </div>
                )
                })
              }
            </div>
          </div>
          <div>
            <label className="colorWhite bold" style={{marginBottom: 5}}>Thickness</label>
            <div className="slidecontainer">
              <input
                type="range"
                className="custom-range"
                id="stroke"
                value={this.state.stroke}
                onChange={this.onStroke.bind(this)}
                step={1}
                max={40}
                min={1}
                />
            </div>
            <div className="boardpanel spacebetween">
              <label className="colorWhite">Thin</label>
              <label className="colorWhite">Thick</label>
            </div>
          </div>
          <div style={{marginTop: 5}}>
            <label className="colorWhite bold" style={{marginBottom: 5}}>Shadow</label>
            <div className="slidecontainer">
              <input
                type="range"
                className="custom-range"
                id="strokeShadow"
                value={this.state.strokeShadow}
                onChange={this.onShadow.bind(this)}
                step={1}
                max={10}
                min={0}
                />
            </div>
            <div className="boardpanel spacebetween">
              <label className="colorWhite">Thin</label>
              <label className="colorWhite">Thick</label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTextMenu(){
    const size = 6;
    const res = this.drawCol.reduce((acc, curr, i) => {
      if ( !(i % size)  ) {    // if index is 0 or can be divided by the `size`...
        acc.push(this.drawCol.slice(i, i + size));   // ..push a chunk of the original array to the accumulator
      }
      return acc;
    }, []);

    return (
      <div id="drawToolbar" className="secondaryToolbar drawHangerRight" onClick={this.menuClick.bind(this)}>
        <div className="drawToolbarButtonContainer">
          <div>
            <label className="colorWhite bold" style={{marginBottom: 5}}>Colours</label>
            <div>
              {res.map((row, index) => {
                return (
                  <div className="pdfdotRow" key={"r"+index}>
                    {row.map((item, index) => {
                        var c = this.state.color === item?'pdfdotSel':''
                        return (
                          <div key={"d"+index} className={c}><span className="pdfdot" style={{backgroundColor: item}} onClick={this.onFontColourSel.bind(this,item)}></span></div>
                        )
                      })
                    }
                  </div>
                )
                })
              }
            </div>
          </div>
          <div>
            <label className="colorWhite bold" style={{marginBottom: 5}}>Fonts</label>
            <div className="slidecontainer">
              <input
                type="range"
                className="custom-range"
                id="font_size"
                value={this.state.font_size}
                onChange={this.onFonts.bind(this)}
                step={1}
                max={40}
                min={8}
                />
            </div>
            <div className="boardpanel spacebetween">
              <label className="colorWhite">Small</label>
              <label className="colorWhite">Large</label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderToolBar(){
    return super.renderToolBar();
    return super.renderToolBar(
      <div className="boardpanel">
        <div className="pdfBut">
          <AnnaPen id="annotationPen" title="Drawing" className="toolbarButton hiddenLargeView" onClick={this.onAnnaPen.bind(this)}>
            <span>Drawing</span>
          </AnnaPen>
          <DownArrow style={{margin: '3px 0 4px -5px'}} className="toolbarButton" onClick={this.onDrawMenu.bind(this)}/>
        </div>
        <div className="pdfBut" style={{marginLeft: 5}} onClick={this.onAnnaText.bind(this)}>
          <RectIcon id="annotationText" title="Add Text" className="toolbarButton hiddenLargeView" onClick={this.onTrash.bind(this)}>
            <span>Add Text</span>
          </RectIcon>
          <FontIcon size={10} style={{marginLeft: -23, marginTop: 11}}/>
          <DownArrow style={{margin: '3px 0 4px 5px'}} className="toolbarButton" onClick={this.onTextMenu.bind(this)}/>
        </div>
        <div className="pdfBut" style={{marginLeft: 5, width: 20}} onClick={this.onAnnaSign.bind(this)}>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M2 17h20" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"></path><path opacity=".75" d="M1.4 7.79A10.51 10.51 0 0 1 4 6.17a89.034 89.034 0 0 1 8.31-3.54.88.88 0 0 1 .79 0c.44.27.59 1.1.65 1.8a34.32 34.32 0 0 1-2.59 15.83 6.171 6.171 0 0 1-1 1.75 1.29 1.29 0 0 1-1.49.44c-.8-.45-1.26-1.74-1.47-2.95a8.06 8.06 0 0 1 .12-4 5.57 5.57 0 0 1 1.11-1.76 27 27 0 0 1 8.51-6.89l1.26 4.21c.09.32.25.69.47.66.22-.03.21-.16.28-.3A28.432 28.432 0 0 0 21.4 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 14l3-3m-3 0l3 3" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round"></path></svg>
        </div>
        <div className="pdfBut" style={{marginLeft: 15}}>
          <TrachIcon id="annotationTrash" title="Delete All" className="toolbarButton hiddenLargeView" onClick={this.onTrash.bind(this)}>
            <span>Delete All</span>
          </TrachIcon>
        </div>

        {this.state.drawMenu &&
          this.renderDrawMenu()
        }
        {this.state.textMenu &&
          this.renderTextMenu()
        }
      </div>,
    )
  }
}

export class PDFViewer extends PDFAnnotation {
  constructor(props) {
    super(props);

  }

  render(){
    var option = {
      fullscreen: false,
      showToolBar: true,
      embedded: false,

      boxHeader: true,
      boxFooter: true,
    }
    var { fullscreen, showToolBar, embedded } = this.props
    if(fullscreen === true){
      option.fullscreen = true
      option.boxHeader = false
      option.boxFooter = false
    }
    if(showToolBar !== undefined){
      option.showToolBar = showToolBar
    }
    if(embedded === true){
      option.embedded = true
      option.boxHeader = false
      option.boxFooter = false
    }
    var style = {};
    if (this.props.zIndex) {
      style.zIndex = this.props.zIndex;
    }
    return (
      <div className={`${option.embedded ? "center" : "aBoxTop-overlay"}`} style={style} onClick={this.clear.bind(this)} ref={this.refMain} id="pdf-container">
        {this.renderPdf(option)}
      </div>
    )
  }
}
