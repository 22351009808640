import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Badge, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, Popover, Radio, Select, Stack, TextField, Tooltip } from '@mui/material';

import InboxIcon from '@mui/icons-material/Inbox';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplyIcon from '@mui/icons-material/Reply';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BlockIcon from '@mui/icons-material/Block';

import './contributorInbox.css';
import { baseContributorActions } from '../../actions/contributor.base.actions';
import { fileActions, popoverAction } from '../../actions/admin';
import { ReadFileAsArrayBuffer, sizeToStringVariable, userNameOrder } from '../../lib/simpletools';
import { MuiButton, MuiCheckbox, MuiSelect, OptionsMenu } from '../common/MUI';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BinderItemType, FeatureStatus, FreemiumType, LocalStorageVariableNames, RoutesConstants } from '../../constants';
import { binderService } from '../../services/admin';
import { v4 as uuidv4 } from 'uuid';
import { history } from '../../lib';
import { contributorReducerConstants } from '../../reducers/contributors.reducer';
import { PDFDocument } from 'pdf-lib';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';


export const ContributorFileBroadCastEvents = {
    download_complete: 'download_complete',
    download_error: 'download_error',
}

export function ContributorInboxIcon() {
    const dispatch = useDispatch();
    const showInbox = useSelector(state => { return state.contributors && state.contributors.showInbox });
    const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : null });
    const currentBoardId = useSelector(state => { try { return state.board.currentBoard.id } catch { return '' } });
    const isSuspended = useSelector(state => { try { return state.authentication ? Boolean(state.authentication.suspendedCustomers.find(c => c.customerId == currentCustomerId)) : null } catch { return false } });
    // const currentCustomerType = useSelector(state => { try { return state.authentication.customers.find(c => c.id == currentCustomerId).accountType } catch { return null } });
    const contributorFeatureStatus = useSelector(state => { try { return state.company[currentCustomerId].contributorFeatureStatus } catch { return false } });
    const currentCustomerContributorFiles = useSelector(state => { return state.contributors && state.contributors.files && state.contributors.files[currentCustomerId] ? state.contributors.files[currentCustomerId] : [] });
    const iconRef = useRef(null);

    useEffect(() => {
        return () => {
            dispatch(baseContributorActions.setShowMenu(false));
            dispatch(baseContributorActions.setAnchorElement(null));
        }
    }, []);

    useEffect(() => {
        dispatch(baseContributorActions.setAnchorElement(iconRef ? iconRef.current : null));
    }, [iconRef]);

    if (contributorFeatureStatus == FeatureStatus.Hidden || isSuspended) {
        return null;
    }

    const handleClick = (event) => {
        if (contributorFeatureStatus != FeatureStatus.Enabled) { return; }
        const show = !showInbox;
        dispatch(baseContributorActions.setShowMenu(show));
        dispatch(baseContributorActions.setAnchorElement(event.currentTarget));
    };

    const filteredFiles = currentCustomerContributorFiles ? currentCustomerContributorFiles.filter(f => { if (!f.boardId) { return true; } return f.boardId == currentBoardId }) : [];
    const isDisabled = contributorFeatureStatus != FeatureStatus.Enabled;
    return <Tooltip title={isDisabled ? LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('contributor', true, false) : 'Contributor inbox'} disableInteractive={true}>
        <Badge badgeContent={isDisabled ? undefined : filteredFiles && filteredFiles.length ? filteredFiles.length : undefined} color="success">
            <div id='contributor-inbox-icon' ref={iconRef} className={'contributor-inbox-icon' + (showInbox ? ' contributor-inbox-icon-active' : '') + (isDisabled ? ' contributor-inbox-icon-disabled' : '')} onClick={(e) => { handleClick(e); }}>
                <InboxIcon />
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Inbox</span>
            </div>
        </Badge>
    </Tooltip>
}

export function ContributorInbox(props) {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ textFilter: '', contributor: null });
    const [isLoading, setIsLoading] = useState(false);
    const [homePageShowAllFiles, setHomePageShowAllFiles] = useState(false);


    const showInbox = useSelector(state => { return state.contributors && state.contributors.showInbox });
    const contributorAnchorEl = useSelector(state => { return state.contributors && state.contributors.anchorEl });
    const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : '' });
    const currentBoardId = useSelector(state => { try { return state.board.currentBoard.id } catch { return '' } });
    const isSuspended = useSelector(state => { try { return state.authentication ? Boolean(state.authentication.suspendedCustomers.find(c => c.customerId == currentCustomerId)) : null } catch { return false } });
    // const currentCustomerType = useSelector(state => { try { return state.authentication.customers.find(c => c.id == currentCustomerId).accountType } catch { return null } });
    const contributorFeatureStatus = useSelector(state => { try { return state.company[currentCustomerId].contributorFeatureStatus } catch { return false } });
    const isLoadingContributorStatus = useSelector(state => { try { return !Boolean(state.company[currentCustomerId].contributorFeatureStatus) } catch { return true } });

    const currentCustomerContributorFiles = useSelector(state => { return state.contributors && state.contributors.files && state.contributors.files[currentCustomerId] ? state.contributors.files[currentCustomerId] : [] });
    const contributors = useSelector(state => { return currentCustomerId && state.contributors && state.contributors.contributors ? state.contributors.contributors[currentCustomerId] : [] });
    const contributorsLoading = useSelector(state => { try { return state.contributors.isLoading[currentCustomerId] || { loading: false, loaded: false } } catch { return { loading: false, loaded: false } } });
    const selectedNotifications = useSelector(state => { try { return state.contributors.selectedNotifications; } catch { return {}; } })
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    const loadingCustomerId = useRef("");

    const lastLoginDate = useSelector(state => {
        try {
            return state.authentication.devices
                .filter(d => d.appId == LocalStorageVariableNames.AthenaWeb)
                .sort((a, b) => { return (new Date(b.lastLoginTime).getTime() - new Date(a.lastLoginTime).getTime()) })[0].lastLoginTime;
        } catch { return null; }
    });
    const fileLimitPerContributor = useSelector(state => { return state.company && state.company[currentCustomerId] ? state.company[currentCustomerId].fileLimitPerContributor : null });

    var contributorsWithUploadedFilesIds = {};
    (currentCustomerContributorFiles || []).forEach(f => {
        if (!f.boardId || f.boardId == currentBoardId) {
            contributorsWithUploadedFilesIds[f.contributorId] = ''
        }
    });
    contributorsWithUploadedFilesIds = Object.keys(contributorsWithUploadedFilesIds);

    useEffect(() => {
        if (props.homePageView) { return; }
        if (!currentCustomerId || (contributorFeatureStatus != FeatureStatus.Enabled) || isSuspended) { return; }
        if (loadingCustomerId && loadingCustomerId.current == currentCustomerId && isLoading) { return }
        if (!contributorsLoading || contributorsLoading.loading || contributorsLoading.loaded) { return; }
        loadingCustomerId.current = currentCustomerId;
        
        dispatch({ type: contributorReducerConstants.SET_LOADING, payload: { id: currentCustomerId, loading: true, loaded: false } });
        setIsLoading(true);
        loadContributorMemberships();
        loadContributors().then(() => {
            loadContributorFiles();
        });
    }, [currentCustomerId, isSuspended, contributorFeatureStatus]);

    useEffect(() => {
        try {
            if (window.selectedContributorId) {
                setFilter({ ...filter, contributor: { label: contributors && contributors[window.selectedContributorId] ? userNameOrder(contributors[window.selectedContributorId].firstName, contributors[window.selectedContributorId].lastName, displaySettings.userSort == false ? false : true) + (fileLimitPerContributor ? ` (${currentCustomerContributorFiles.filter(f => { return f.contributorId == window.selectedContributorId }).length}/${fileLimitPerContributor} files)` : '') : '', value: window.selectedContributorId } });
                delete window.selectedContributorId;
            }
        } catch { }
    }, [window.selectedContributorId]);

    // Return early
    if (contributorFeatureStatus == FeatureStatus.Hidden || isSuspended) {
        return null;
    }

    function loadContributorMemberships() {
        return new Promise((resolve, reject) => {
            dispatch(baseContributorActions.getContributorMemberships(null, null, currentCustomerId))
                .then(() => { resolve() })
                .catch(() => { reject(); })
                .finally(() => {
                })
        });
    }

    function loadContributorFiles() {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            dispatch(baseContributorActions.getAllContributorFilesForCustomer(currentCustomerId))
                .then(() => { resolve() })
                .catch(() => { reject(); })
                .finally(() => {
                    setIsLoading(false);
                    dispatch({ type: contributorReducerConstants.SET_LOADING, payload: { id: currentCustomerId, loading: false, loaded: true } });
                })
        });
    }

    function loadContributors() {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            dispatch(baseContributorActions.getContributors(currentCustomerId))
                .then(() => { resolve() })
                .catch(() => { reject(); })
                .finally(() => {
                    // setIsLoading(false);
                })
        });
    }

    function renderTextFilter() {
        return <div data-sl="mask" style={{ display: 'flex', flexDirection: 'row', gap: '10px', flex: 1, alignItems: 'center', width: '100%' }}>
            <TextField
                style={{ flex: 1 }}
                label='Search'
                variant='standard'
                placeholder='Search files'
                InputLabelProps={{
                    shrink: true
                }}
                onChange={(event) => { setFilter({ ...filter, textFilter: event.currentTarget.value }) }}
                InputProps={{
                    endAdornment: filter.textFilter ? <CloseIcon onClick={() => { setFilter({ ...filter, textFilter: '' }) }} /> : null
                }}
                value={filter.textFilter}
            />
            {!props.homePageView ? <Tooltip title='Refresh list' disableInteractive={true}>
                <span>
                    <RefreshIcon className='contributor-inbox-popover-refresh-icon' onClick={() => { loadContributorFiles() }} />
                </span>
            </Tooltip> : null}
        </div>
    }

    function renderUserFilter() {
        return <Autocomplete
            style={{ flex: 1, width: '100%' }}
            clearOnEscape
            value={filter.contributor}
            options={contributorsWithUploadedFilesIds ? contributorsWithUploadedFilesIds.map(c => { return { label: contributors && contributors[c] ? userNameOrder(contributors[c].firstName, contributors[c].lastName, displaySettings.userSort == false ? false : true) + `${contributors[c].isDeleted ? ' (Deleted)' : ''}` + (fileLimitPerContributor ? ` (${currentCustomerContributorFiles.filter(f => { return f.contributorId == c }).length}/${fileLimitPerContributor} files)` : '') : '', value: c } }).filter(c => Boolean(c)) : []}
            onChange={(event, newValue) => { setFilter({ ...filter, contributor: newValue ? newValue : null }); }}
            renderInput={(params) => {
                return (
                    <TextField {...params} inputProps={{ ['data-sl']: "mask" }} className='fs-exclude' fullWidth={true} label="Contributor" variant="standard" />
                )
            }}
        />
    }

    function renderContent() {
        if (isLoading) {
            return <div className={props.homePageView ? '' : `contributor-inbox-popover`}>
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress color='success' />
                </div>
            </div>
        }

        return <div className={props.homePageView ? '' : `contributor-inbox-popover`}>
            <div className='contributor-inbox-popover-top-menu' >
                <div style={{ fontWeight: 'bold' }}>Contributor files</div>
                {/* {fileLimitPerContributor ? <div style={{ color: 'gray', fontSize: '15px', alignSelf: 'end' }}>File limit per contributor: {fileLimitPerContributor}</div> : null} */}
                {renderTextFilter()}
                {renderUserFilter()}
                {!props.useDroppable
                    ? <div style={{ textAlign: 'right', width: '100%', paddingTop: '10px' }}>
                        <MuiButton
                            variant='contained'
                            disabled={!Object.keys(selectedNotifications).length}
                            type='red'
                            onClick={() => {
                                var selectedNotifIds = Object.keys(selectedNotifications);
                                dispatch(popoverAction.showDialog({
                                    dialogId: 'contributor-check-multi-delete-dialog',
                                    title: `Are you sure you want to delete the selected item${selectedNotifIds.length == 1 ? '' : 's'}?`,
                                    content: '',
                                    dialogActions: <Stack direction='row' spacing={2}>
                                        <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('contributor-check-multi-delete-dialog')) }}>Cancel</MuiButton>
                                        <MuiButton variant='contained' type='red' onClick={() => {
                                            dispatch(popoverAction.remove('contributor-check-multi-delete-dialog'));
                                            selectedNotifIds.forEach(k => {
                                                dispatch(baseContributorActions.deleteContributorFile(k));
                                            });
                                        }}
                                        >Yes</MuiButton>
                                    </Stack>

                                }))
                            }}
                        >Delete file{Object.keys(selectedNotifications).length > 1 ? 's' : ''}</MuiButton>
                    </div>
                    : <div style={{ color: 'gray', fontSize: '15px', fontWeight: 'bold', padding: '10px', textAlign: 'center' }}>Drag and drop to add inbox items to the binder</div>
                }
                {/* {filter.contributor ? <div>{currentCustomerContributorFiles.filter(f => { return f.contributorId == filter.contributor.value }).length}</div> : null} */}
            </div>

            <div className='contributor-inbox-popover-file-items'>
                {renderFileItems()}
            </div>
            <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                <MuiButton variant='contained' fullWidth={true} onClick={() => { dispatch(baseContributorActions.setShowMenu(false)); }}>Close</MuiButton>
            </div>
        </div>
    }

    function renderFileItems() {
        if (!currentCustomerContributorFiles.length) {
            return <div style={{ textAlign: 'center' }}>No files</div>
        }
        return currentCustomerContributorFiles
            .filter(f => { if (!f.boardId) { return true; } return f.boardId == currentBoardId })
            .filter(f => { return selectedNotifications[f.id] || ((filter.contributor ? f.contributorId == filter.contributor.value : true) && f.name.toLowerCase().includes(filter.textFilter)) })
            .sort((a, b) => { return new Date(b.dateCreated) - new Date(a.dateCreated) })
            .map((f, index) => {
                var contributorName = '';
                try {
                    contributorName = userNameOrder(contributors[f.contributorId].firstName, contributors[f.contributorId].lastName, displaySettings.userSort == false ? false : true) + `${contributors[f.contributorId].isDeleted ? ' (Deleted)' : ''}`;
                } catch { }
                return <ContributorFileItem
                    useDroppable={props.useDroppable}
                    index={index}
                    AddItemToList={props.AddItemToList}
                    isSelected={selectedNotifications[f.id]}
                    key={f.id}
                    file={f}
                    contributorName={contributorName}
                    contributorEmail={contributors && contributors[f.contributorId] ? contributors[f.contributorId].email : ''}
                />
            });
    }

    function renderHomePageContent() {
        if (contributorFeatureStatus != FeatureStatus.Enabled || isLoading || !contributorsLoading || contributorsLoading.loading) {
            return <div>
                <Accordion elevation={5} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ fontWeight: 'bold' }}>Contributed files</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: 'flex', flex: '1', alignContent: 'center', justifyContent: 'center', padding: '20px' }}>
                            {contributorFeatureStatus != FeatureStatus.Enabled && !isLoadingContributorStatus
                                ? <div>{LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('contributor', true, true).map(i => <div>{i}</div>)}</div>
                                : <CircularProgress color='success' size={28} />
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div >;
        }

        var files = currentCustomerContributorFiles.filter(cf => { return (!cf.boardId || cf.boardId == currentBoardId) }).filter(cf => { return Boolean(!lastLoginDate || (new Date(cf.dateCreated).getTime() - new Date(lastLoginDate).getTime() >= 0)) });
        var filesWithoutBoardId = currentCustomerContributorFiles.filter(f => !f.boardId);
        files = files.sort((a, b) => { return new Date(b.dateCreated) - new Date(a.dateCreated) });
        var hasNewFiles = Boolean(files.length) && !homePageShowAllFiles;
        if (!hasNewFiles) {
            files = currentCustomerContributorFiles.filter(cf => { return (!cf.boardId || cf.boardId == currentBoardId) }).sort((a, b) => { return new Date(b.dateCreated) - new Date(a.dateCreated) })
            if (!homePageShowAllFiles) {
                files = files.slice(0, 5);
            }
        }
        var contributorElements = files
            .filter(f => { if (!f.boardId) { return true; } return f.boardId == currentBoardId })
            .filter(f => { return selectedNotifications[f.id] || ((filter.contributor ? f.contributorId == filter.contributor.value : true) && f.name.toLowerCase().includes(filter.textFilter)) })
            .sort((a, b) => { return new Date(b.dateCreated) - new Date(a.dateCreated) })
            .map((f, index) => {
                var contributorName = '';
                try {
                    contributorName = userNameOrder(contributors[f.contributorId].firstName, contributors[f.contributorId].lastName, displaySettings.userSort == false ? false : true) + `${contributors[f.contributorId].isDeleted ? ' (Deleted)' : ''}`;
                } catch { }
                return <ContributorFileItem
                    useDroppable={props.useDroppable}
                    index={index}
                    homePageView={props.homePageView}
                    AddItemToList={props.AddItemToList}
                    isSelected={selectedNotifications[f.id]}
                    key={f.id}
                    file={f}
                    contributorName={contributorName}
                    contributorEmail={contributors && contributors[f.contributorId] ? contributors[f.contributorId].email : ''}
                />
            });

        if (!contributorElements.length && !homePageShowAllFiles) {
            return <div>
                <Accordion elevation={5} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ fontWeight: 'bold' }}>Contributed files</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div style={{ textAlign: 'center' }}>There are no contributed files yet.</div>
                        </div>
                        <div style={{ paddingTop: '30px', textAlign: 'center' }}>
                            <MuiButton variant='outlined' onClick={() => {
                                history.push({
                                    pathname: RoutesConstants.people,
                                    query: { showContributors: true }
                                })
                            }}>View contributors</MuiButton>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div >;
        }

        return <div>
            <Accordion elevation={5} defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {hasNewFiles ? <div style={{ fontWeight: 'bold' }}>New contributed files{files.length ? ` (${files.length})` : ''}</div> : <div style={{ fontWeight: 'bold' }}>Contributed files</div>}
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {<div style={{ fontWeight: 'bold', paddingBottom: '10px', color: 'var(--ansarada-green)' }}>{hasNewFiles ? 'New files' : 'Recent files'}:</div>}
                        {homePageShowAllFiles
                            ? <div className='contributor-inbox-home-page-filter-wrapper'>
                                {renderTextFilter()}
                                {renderUserFilter()}
                            </div> : null}
                        {homePageShowAllFiles && !contributorElements.length
                            ? <div style={{ padding: '20px', textAlign: 'center' }}>No files found.</div>
                            : <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                                {contributorElements.length ? contributorElements : <div style={{ textAlign: 'center' }}>There are no {hasNewFiles ? 'new' : 'recent'} contributed files.</div>}
                            </div>}
                    </div>
                    {!homePageShowAllFiles ? <div style={{ textAlign: 'center' }}>
                        { }
                        <div className='contributor-inbox-home-page-show-all-button' onClick={() => { setHomePageShowAllFiles(true) }}><KeyboardArrowDownIcon style={{ width: '40px', height: '40px' }} color='rgba(0, 0, 0, 0.54)' /></div>
                    </div> : null}
                </AccordionDetails>
            </Accordion>
        </div >
    }

    if (props.homePageView) {
        return <div>
            {renderHomePageContent()}
        </div>
    }

    return <div>
        {showInbox && contributorAnchorEl
            ? <Popover
                id='contributor-inbox-popover'
                open={showInbox}
                // anchorOrigin={{horizontal:'right', vertical: 'center'}}
                // anchorPosition={{left: '100%', top: '50%'}}
                anchorEl={contributorAnchorEl}
                transition={true}
                onClose={() => { dispatch(baseContributorActions.clearSelectedNotifications()); dispatch(baseContributorActions.setShowMenu(false)); }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={contributorAnchorEl.id == 'binder-wizard-right-side-page-anchor'
                    ? { vertical: 'center', horizontal: 'right' }
                    : { vertical: 'top', horizontal: 'right' }
                }
            >
                {renderContent()}
            </Popover>
            : null
        }
    </div>
}

function ContributorFileItem(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [rejectReason, setRejectReason] = useState({ showRejectDialog: false, rejectType: "Custom", rejectReasonText: "" });
    const [viewFileState, setViewFileState] = useState(false);
    // const customerId = useSelector(state => { try { return state.authentication.customerId; } catch { return null; } })
    const kUser = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId]; } catch { return null; } })
    const fileInformation = useSelector(state => { try { return state.file[props.file.id]; } catch { return null; } })

    useEffect(() => {
        if (viewFileState && fileInformation && !fileInformation.loading && fileInformation.data) {
            dispatch(popoverAction.showPDF({ ...fileInformation, title: props.file.name }));
            // if (!props.file.pageCount) {
            //     try {
            //         ReadFileAsArrayBuffer(fileInformation.data).then(arrayBuffer => {
            //             PDFDocument.load(arrayBuffer).then(pdf => {
            //                 var pageCount = pdf.getPageCount();
            //                 if (pageCount) {
            //                     dispatch(baseContributorActions.updateContributorFile(props.file.id, customerId, { pageCount: pageCount }));
            //                 }
            //             });
            //         });
            //     } catch { }
            // }
        }
    }, [viewFileState, fileInformation]);

    useEffect(() => {
        const bc = new BroadcastChannel(props.file.id);
        bc.onmessage = (event) => {
            if (event.data) {
                switch (event.data.message) {
                    case ContributorFileBroadCastEvents.download_error:
                        dispatch(popoverAction.showError({ title: `Error downloading file`, body: props.file.name }))
                        setIsLoading(false);
                        break;
                    case ContributorFileBroadCastEvents.download_complete:
                        setIsLoading(false);
                        break;
                    default:
                        return;
                }
            }
        };
        return () => { bc.close() };
    }, []);

    function downloadFile(populateOnly = false) {
        setIsLoading(true);
        dispatch((populateOnly ? fileActions.PopulateFile : fileActions.DownloadFile)({
            ...props.file,
            fileName: props.file.name + ".pdf",
            displayName: props.file.name,
            fileSize: props.file.size,
            // key: decryptKey,
            kUser: kUser.pUserGenSec,
            broadcastChannelId: props.file.id,
        }));
    }

    function viewFile() {
        if (isLoading) { return; }
        if (!fileInformation || !fileInformation.data) {
            downloadFile(true);
        }
        if (fileInformation && !fileInformation.loading && fileInformation.data) {
            dispatch(popoverAction.showPDF({ ...fileInformation, title: props.file.name }));
        }
        setViewFileState(true);
    }

    async function addItemToBinder() {
        try {
            const newDocumentId = uuidv4();
            await binderService.copyDocuments([{ newDocumentId: newDocumentId, originalDocumentId: props.file.documentId }]);
            props.AddItemToList({
                binderType: BinderItemType.document,
                itemName: props.file.name,
                documentId: newDocumentId,
                // hasDocument: true,
                itemId: newDocumentId,
                id: newDocumentId,
                contributorKey: props.file.key,
                isContributorFile: true,
                genseckey: true,
                size: props.file.size,
                fileName: props.file.name + '.pdf',
                loading: false,
                itemdata: null,
                indentCount: 0,
                adminPosition: -1,
            });
        } catch {
        }
    }

    function deleteFile() {
        dispatch(popoverAction.showDialog({
            title: 'Are you sure you want to delete this contributed file?',
            content: <div>
                <div>Are you sure you want to delete this file: {props.file.name}?</div>
            </div>,
            dialogId: 'contributor-inbox-delete-file-check',
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('contributor-inbox-delete-file-check')); }}>Cancel</MuiButton>
                <MuiButton type='red' variant='contained' onClick={() => {
                    dispatch(popoverAction.remove('contributor-inbox-delete-file-check'));
                    dispatch(baseContributorActions.deleteContributorFile(props.file.id))
                        .then(() => {
                            dispatch(popoverAction.showMessage({ title: 'File deleted', body: props.file.name }));
                        }, () => { })
                        .catch(() => {
                            dispatch(popoverAction.showError({ title: 'Error deleting contributor file', body: props.file.name }));
                        })
                        .finally(() => {
                        });
                }}>Yes</MuiButton>
            </Stack>
        }))
    }

    function renderRejectFileDialog() {
        if (!rejectReason.showRejectDialog) { return null; }
        const isRejectDisabled = !rejectReason || !rejectReason.rejectType || (rejectReason.rejectType == 'Custom reason' && !rejectReason.rejectReasonText);
        return <Dialog width='sm' fullWidth={true} open={true}>
            <DialogTitle><b>Are you sure you want to reject this contributed file - {props.file.name}?</b></DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', padding: '10px 0 20px 0' }}>
                    <div>Are you sure you want to reject this file?</div>
                    <div style={{ paddingBottom: '10px' }}>The contributor will be notified the file has been rejected.</div>

                    <MuiSelect label='Reason *'
                        options={
                            ["Custom reason",
                                "Document has too many pages",
                                "Document is late",
                                "Document is too large",
                                "Document exceeds binder size limit",
                            ].map(rs => { return { label: rs, value: rs } })
                        }
                        onChange={(value) => { setRejectReason({ ...rejectReason, rejectType: value }) }}
                    />

                    {rejectReason.rejectType == "Custom reason"
                        ? <div>
                            <TextField title="Custom reason" multiline={true} maxRows={3} rows={3} disabled={rejectReason.rejectType != "Custom reason"} label="Custom reason" fullWidth={true} value={rejectReason.rejectReasonText} onChange={(event) => { setRejectReason({ ...rejectReason, rejectReasonText: event.currentTarget.value }) }} />
                        </div>
                        : null
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { setRejectReason({ rejectReasonText: '', showRejectDialog: false }) }}>Cancel</MuiButton>
                    <Tooltip title={isRejectDisabled ? "Select a reason" : ""} placement='top'>
                        <span>
                            <MuiButton variant='contained' type='red' disabled={isRejectDisabled}
                                onClick={() => {
                                    dispatch(baseContributorActions.rejectContributorFile(props.file.id, rejectReason.rejectType == 'Custom reason' ? rejectReason.rejectReasonText : rejectReason.rejectType));
                                    setRejectReason({ ...rejectReason, showRejectDialog: false });
                                }}
                            >Yes</MuiButton>
                        </span>
                    </Tooltip>
                </Stack>
            </DialogActions>
        </Dialog>
    }

    var fileItemElement = <React.Fragment>
        <div className={`contributor-inbox-file-item-main${props.homePageView ? ' contributor-inbox-file-item-main-homepage' : ''}`}>
            {props.homePageView ? null : <div data-sl="mask" className='contributor-inbox-file-item-contributor-name fs-exclude'>{props.contributorName}</div>}
            <Tooltip
                disableInteractive={true}
                title={<div><div>{props.file.name}</div>{props.file.message ? <div>{props.file.message}</div> : null}</div>}
                placement={props.homePageView ? 'top' : 'left'}
            >
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', gap: '10px', alignItems: 'center', overflow: 'hidden', cursor: props.useDroppable || props.homePageView ? 'inherit' : 'pointer' }} onClick={() => { if (props.file.isDeleting || props.useDroppable || props.homePageView) { return; } dispatch(baseContributorActions.toggleNotificationSelect(props.file.id)) }}>
                    {props.homePageView ? null : !props.useDroppable ? <MuiCheckbox disabled={props.file.isDeleting || false} readonly={props.file.isDeleting || false} checked={props.isSelected || false} value={props.isSelected || false} /> : <Tooltip disableInteractive={true} title='Append to binder'><div style={{ cursor: 'pointer' }} onClick={() => { addItemToBinder() }}><ReplyIcon /></div></Tooltip>}
                    <div style={{ flex: 1 }} data-sl="mask" className='contributor-inbox-file-item-name fs-exclude'>{props.file.name}</div>
                </div>
            </Tooltip>
            <div className='contributor-inbox-file-item-details'>
                {props.homePageView ? <div data-sl="mask" className='contributor-inbox-file-item-contributor-name fs-exclude'>{props.contributorName}</div> : null}
                <div className='contributor-inbox-file-item-date'>{moment(props.file.dateCreated).format('LLL')}</div>
                <div style={{ textAlign: 'right' }}>
                    {props.file.pageCount ? <div className='contributor-inbox-file-item-pageCount'>{props.file.pageCount} {props.file.pageCount == 1 ? 'page' : 'pages'}</div> : <div className='contributor-inbox-file-item-pageCount'></div>}
                    <div className='contributor-inbox-file-item-size'>{sizeToStringVariable(props.file.size)}</div>
                </div>
            </div>
        </div>
        <div className={`contributor-inbox-file-item-options${isLoading || props.file.isDeleting || props.isSelected ? ' contributor-inbox-file-item-options-show' : ''}`}>
            {isLoading || props.file.isDeleting
                ? <CircularProgress size={20} color='success' />
                : props.isSelected
                    ? null
                    : <OptionsMenu options={[
                        { title: 'View document', key: props.file.id + '-view-tooltip', element: <div style={{ width: '100%' }} onClick={() => { if (isLoading) { return; } viewFile(); }}><VisibilityIcon /> View document</div> },
                        { title: 'Download document', key: props.file.id + '-download-tooltip', element: <div style={{ width: '100%' }} onClick={() => { if (isLoading) { return; } downloadFile(); }}><DownloadIcon /> Download document</div> },
                        { title: 'Reject document', key: props.file.id + '-reject-tooltip', element: <div style={{ width: '100%' }} onClick={() => { if (isLoading) { return; } setRejectReason({ rejectReasonText: '', showRejectDialog: true }); }}><BlockIcon /> Reject document</div> },
                        { title: 'Delete document', key: props.file.id + '-delete-tooltip', element: <div style={{ width: '100%' }} onClick={() => { if (isLoading) { return; } deleteFile(); }}><DeleteIcon color='error' /> Delete document</div> },
                    ]} />
                // [
                //     <Tooltip key={props.file.id + '-view-tooltip'} title='View document' disableInteractive={true}><div key={props.file.id + '-view'} className='contributor-inbox-file-item-options-option' onClick={() => { if (isLoading) { return; } viewFile(); }}><VisibilityIcon /></div></Tooltip>,
                //     <Tooltip key={props.file.id + '-download-tooltip'} title='Download document' disableInteractive={true}><div key={props.file.id + '-download'} className='contributor-inbox-file-item-options-option' onClick={() => { if (isLoading) { return; } downloadFile(); }}><DownloadIcon /></div></Tooltip>,
                //     <Tooltip key={props.file.id + '-delete-tooltip'} title='Delete document' disableInteractive={true}><div key={props.file.id + '-delete'} className='contributor-inbox-file-item-options-option' onClick={() => { if (isLoading) { return; } deleteFile(); }}><DeleteIcon color='error' /></div></Tooltip>
                // ]
            }
        </div>
        {renderRejectFileDialog()}
    </React.Fragment>;

    if (!props.useDroppable) {
        return <div className='contributor-inbox-file-item'>{fileItemElement}</div>;
    }

    return <Draggable
        key={'contributorFile-' + props.file.id}
        draggableId={'contributorFile-' + props.file.documentId}
        index={props.index}
        isDropDisabled={false}
        isDragDisabled={false}
    >
        {(provided, snapshot) => (
            <div
                className='contributor-inbox-file-item'
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {fileItemElement}
            </div>
        )}
    </Draggable>
}