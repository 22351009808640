/* global appConfig, deviceConfig */
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType } from '../../lib/';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import {keysStorage, FileStorage, CacheStorage, MinuteStorage} from '@lib/indexeddb';
import fetch from '../../lib/fetch-retry';
import { CheckImpersonationPath, getUserKeyPath, AnsaradaCredentials, TrackEvent } from '../../lib/simpletools';
import { osVersion, osName, browserVersion, browserName, isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { UserTypeEnum } from '@constants/common.constants';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import createAuth0Client from '@auth0/auth0-spa-js';
import { baseUserService } from '@services/user.base.service';

const athenaAppID = 'AthenaWeb'
export const userService = {
    login: baseUserService.login,
    logout: baseUserService.logout,
    loginDemo: baseUserService.loginDemo,
    Auth0Login: baseUserService.Auth0Login,
    Auth0Logout: baseUserService.Auth0Logout,
    hasDevice: baseUserService.hasDevice,
    hasAlias: baseUserService.hasAlias,
    registerDevice: baseUserService.registerDevice,
    registerUserDevice: baseUserService.registerUserDevice,
    registerUserDeviceWithKey: baseUserService.registerUserDeviceWithKey,
    registerNewUser,
    forgotNC: baseUserService.forgotNC,
    forgotNCCode: baseUserService.forgotNCCode,
    forgotNewPass,
    forgotWCard: baseUserService.forgotWCard,
    forgotWCardNewPass: baseUserService.forgotWCardNewPass,
    resetKey: baseUserService.resetKey,
    sendResetLink: baseUserService.sendResetLink,
};

function forgotNewPass(item) {
  console.log('forgotNewPass');
  if(item && item.username) { TrackEvent('Forgot-password', { username: item.username || "", alias: item.alias || "" }); }
  return baseUserService.forgotNewPass(item, true);
}

function registerNewUser(regitem, sendToken = false){
  console.log('registerNewUser');
  return registerNewUserPart1(regitem, sendToken)
  .then(data => {
    console.log("step 6");
    if(data.kGenericSeceretariatOld) return data
    return new Promise(async(resolve, reject) => {
      try{
        var genSecRegistrations = []
        async function addGenSecReg(type, key){
          var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
          var keys = await CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
          var publickeypem = await CrytpoLib.exportPublicKeyToPEM(keys.publicKey)
          var privateKeypem = await CrytpoLib.exportPrivateKeyToPEM(keys.privateKey)
          //AES(KGenSecWrap, GenSecKUserPrivate)
          var GenSecKUserPrivate = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(privateKeypem))
          var GenSecKUserPrivateSignature = await RSACrypto.SignWithDevice(GenSecKUserPrivate)
          var GenSecKUserPublicSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(publickeypem))
          var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
          var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
          return {
            kGenSecUser: privateKeypem,
            genSecData : {
              targetUserType: type,
              kGenSecWrap: CrytpoLib.arrayBufferToBase64String(kGenSecWrap),
              genSecKUserPrivate: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivate),
              genSecKUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivateSignature),
              genSecKUserPublic: publickeypem,
              genSecKUserPublicSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPublicSignature),
            }
          }
        }

        var j = {
          password: data.password,
          kUser: data.kUser,
          kUserSensitiveAES: data.kUserSensitiveAES,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kTransport: data.kTransport,
          kUserTransport: data.kUserTransport,
          kUserTransportSignature: data.kUserTransportSignature,
          kUserPrivate: data.kUserPrivate,
          kUserPrivateSignature: data.kUserPrivateSignature,
          kUserPublic: data.kUserPublic,
          //kUserPublicSignature: data.kUserPublicSignature,
          kUserSensitive: data.kUserSensitive,
          kUserSensitiveSignature: data.kUserSensitiveSignature,
          auth0PasswordWrap: data.auth0PasswordWrap,
          salt: data.salt,
          //kGenSecWrap: data.kGenSecWrap,
          //kGenSecWrappedKUser: data.kGenSecWrappedKUser,
          //kGenSecWrapBytes: data.KGenSecWrapBytes,
          genSecRegistrations: null,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kGenericSeceretariatOld: data.kGenericSeceretariatOld,
        }

        if(data.kGenericSeceretariat === ""){
          var lastPem = data.kUserPublic, lastKey = null, lastPublicKey = null;
          if(regitem.unregisteredUserType === UserTypeEnum.Master){
            var m = await addGenSecReg(UserTypeEnum.Master, lastPem)
            lastPem = m.genSecData.genSecKUserPublic
            lastPublicKey = m.genSecData.genSecKUserPublic
            lastKey = m.kGenSecUser
            genSecRegistrations.push(m.genSecData)
          }
          var p = await addGenSecReg(UserTypeEnum.Publish, lastPem)
          lastPem = p.genSecData.genSecKUserPublic
          if(lastKey === null)
            lastKey = p.kGenSecUser
          if(lastPublicKey === null){
            lastPublicKey = p.genSecData.genSecKUserPublic
          }
          genSecRegistrations.push(p.genSecData)
          var c = await addGenSecReg(UserTypeEnum.Create, lastPem)
          lastPem = c.genSecData.genSecKUserPublic
//          if(lastKey === null)
//            lastKey = c.kGenSecUser
          genSecRegistrations.push(c.genSecData)
          var u = await addGenSecReg("User", lastPem)
          //lastPem = u.genSecKUserPublic
          genSecRegistrations.push(u.genSecData)

          j.genSecRegistrations = genSecRegistrations
          j.kGenSecUser = lastKey
          j.kGenPublicKey = lastPublicKey
        }
        resolve(j)
      }catch(error){
        reject("step7 "+error);
      }
    })
  })
  .then(data => {
    console.log("step 7");

    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        //kUserPublicSignature = RSASign(KDevice, KUserPublicKey)
        var kUserPublicSignatureSign = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(data.kUserPublic))
        //kGenSecWrap = RSA(KGenericSeceretariat, KGenSecWrap)
        var key = data.kGenericSeceretariat!==""?data.kGenericSeceretariat:data.kGenPublicKey;
        var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
        var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
        //kGenSecWrappedKUser = RSA Private Key: AES(KGenSecWrap, KUser)
        var kGenSecWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kUser))

        data.kGenSecWrap = kGenSecWrap
        data.kGenSecWrappedKUser = kGenSecWrappedKUser
        data.kGenSecWrapBytes = KGenSecWrapBytes
        data.kUserPublicSignature = kUserPublicSignatureSign
        resolve(data)
      }catch(error){
        reject("step6 "+error);
      }
    });
  })
  .then(data => {
    console.log("step 7.1");
    if(regitem.unregisteredUserKAthenaAdmin === undefined) return data
    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        var kAthenaAdmin = await CrytpoLib.importPublicKey(regitem.unregisteredUserKAthenaAdmin, CrytpoLib.defaultRSAAlgorithmMethod)
        var kAthenaAdminWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kAthenaAdmin, KGenSecWrapBytes)
        var kAthenaAdminWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kGenSecUser))

        data.kAthenaAdminWrap = kAthenaAdminWrap
        data.kAthenaAdminWrappedKUser = kAthenaAdminWrappedKUser
        resolve(data)
      }catch(error){
        reject("step7 "+error);
      }
    })
  })
  .then(data => {
    console.log("step 8");

    var promisearry = [];
    promisearry.push(
      new Promise((resolve, reject) => {
        resolve({
          password: data.password,
          kUser: data.kUser,
          kUserSensitiveAES: data.kUserSensitiveAES,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kGenericSeceretariatOld: data.kGenericSeceretariatOld,
          kTransport: data.kTransport,
          kUserTransport: data.kUserTransport,
          kUserTransportSignature: data.kUserTransportSignature,
          kUserPrivate: data.kUserPrivate,
          kUserPrivateSignature: data.kUserPrivateSignature,
          kUserPublic: data.kUserPublic,
          kUserPublicSignature: data.kUserPublicSignature,
          kUserSensitive: data.kUserSensitive,
          kUserSensitiveSignature: data.kUserSensitiveSignature,
          auth0PasswordWrap: data.auth0PasswordWrap,
          salt: data.salt,
          kGenSecWrap: data.kGenSecWrap,
          kGenSecWrappedKUser: data.kGenSecWrappedKUser,
          kGenSecWrapBytes: data.KGenSecWrapBytes,
          genSecRegistrations: data.genSecRegistrations,
          kAthenaAdminWrap: data.kAthenaAdminWrap,
          kAthenaAdminWrappedKUser: data.kAthenaAdminWrappedKUser,
        });
      })
    );
    for(var x=0; x<regitem.serialKeys.length; x++){
      promisearry.push(
        new Promise(async(resolve, reject) => {
          var item = regitem.serialKeys[x]
          var kcard = CrytpoLib.GenerateRandom(32)
          try{
            var KCardKey = await CrytpoLib.importPublicKey(item.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
            //KCardWrap = AES Key: RSA(KCard, KCardWrap)
            var KCardWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, KCardKey, kcard)
            //KUser = RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
            var KUserWrap = await CrytpoLib.AESEncrypt(kcard, CrytpoLib.textToArrayBuffer(data.kUser))
            var KUser = await CrytpoLib.AESEncrypt(data.kTransport, KUserWrap)
            //KUserSensitive = AES Key: AES(KTransport, AWS(KCardWrap, KUserSensitive))
            var KUserSensitiveWrap = await CrytpoLib.AESEncrypt(kcard, data.kUserSensitiveAES)
            var KUserSensitive = await CrytpoLib.AESEncrypt(data.kTransport, KUserSensitiveWrap)
            //CardSerialSignature = RSASign(KDevice, CardSerial))
            var cardSerialSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(item.cardSerial))
            //KCardWrapSignature = RSASign(KDevice, RSA(KCard, KCardWrap))
            var KCardWrapSignature = await RSACrypto.SignWithDevice(KCardWrap)
            //KUserSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUser)))
            var kUserSignature = await RSACrypto.SignWithDevice(KUser)
            //KUserSensitiveSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUserSensitive)))
            var kUserSensitiveSignature = await RSACrypto.SignWithDevice(KUserSensitive)
            resolve({
              cardSerial: item.cardSerial,
              kCardWrap: CrytpoLib.arrayBufferToBase64String(KCardWrap),
              kUser: CrytpoLib.arrayBufferToBase64String(KUser),
              kUserSensitive: CrytpoLib.arrayBufferToBase64String(KUserSensitive),
              cardSerialSignature: CrytpoLib.arrayBufferToBase64String(cardSerialSignature),
              kCardWrapSignature: CrytpoLib.arrayBufferToBase64String(KCardWrapSignature),
              kUserSignature: CrytpoLib.arrayBufferToBase64String(kUserSignature),
              kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(kUserSensitiveSignature),
            })
          }catch(error){
            reject("step8 "+error);
          }
        })
      );
    }

    return Promise.all(promisearry);
  })
  .then(arrayValue => {
    console.log("step 10");

    if(arrayValue.length < 2){
      return Promise.reject('Need serial card');
    }

    var data = arrayValue[0];
    var recoveryCardRegistrations = [];
    for(var x=1; x<arrayValue.length; x++){
      recoveryCardRegistrations.push(arrayValue[x]);
    }

    return new Promise((resolve, reject) => {
      const regdata = {
        deviceId: regitem.deviceId,
        deviceHash: regitem.deviceHash,
        username: regitem.username,
        //customerName: regitem.customerName,
        //password: data.password,
        registrationCode: regitem.password,
        kUserTransport: CrytpoLib.arrayBufferToBase64String(data.kUserTransport),
        kUserTransportSignature: CrytpoLib.arrayBufferToBase64String(data.kUserTransportSignature),
        kUserPrivate: CrytpoLib.arrayBufferToBase64String(data.kUserPrivate),
        kUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPrivateSignature),
        kUserPublic: data.kUserPublic,//Public RSA Key
        kUserPublicSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPublicSignature),
        kUserSensitive: CrytpoLib.arrayBufferToBase64String(data.kUserSensitive),
        kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(data.kUserSensitiveSignature),
        salt: CrytpoLib.arrayBufferToBase64String(data.salt),
        recoveryCardRegistrations: recoveryCardRegistrations,
      };
//       if(regitem.alias !== undefined){
//         regdata.registrationCode = regitem.password
// //        regdata.password = data.password
//       }else{
//         regdata.customerName = regitem.customerName
//         regdata.password = data.password
//       }
      if(regitem.checkTermsAndConditions === true){
        regdata.termsAndConditionsAgreed = true
      }

      if(regitem.unregisteredUserKAthenaAdmin !== undefined){
        regdata.kAthenaAdminWrap = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrap)
        regdata.kAthenaAdminWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrappedKUser)
      }

      if(!data.kGenericSeceretariatOld){
        regdata.kGenSecWrap = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrap)
        regdata.kGenSecWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrappedKUser)
      }
      if(data.genSecRegistrations !== null && !data.kGenericSeceretariatOld){
        regdata.genSecRegistrations = data.genSecRegistrations
      }

      if(data.auth0PasswordWrap !== null){
        regdata.auth0Password = data.auth0PasswordWrap
        regdata.auth0PasswordOneShotKeyId = regitem.auth0PasswordOneShotKeyId
      }
      console.log("regdata");

      const requestOptions = {
          method: 'POST',
          headers: sendToken?authHeader():{ 'Content-Type': 'application/json' },
          body: JSON.stringify(regdata)
      };

      fetch(GetURL() + 'Register/NewUser', requestOptions)
      .then(response => {
        if (!response.ok) {
          return response.json();
        }
        resolve();
      })
      .then(data => {
        console.log("data");
        var code = 0;
        var http = 0;
        if(data.hasOwnProperty('Code')){
          code = data.Code;
          http = data.HttpCode;
        }else if(data.hasOwnProperty('code')){
          code = data.code;
          http = data.httpCode;
        }
        if(http === 401 && code === 215){
          resolve();
          return;
        }else if(http > 0 && code > 0){
          reject(data);
        }
        reject('H401');
      })
      .catch((error) => {
        reject();
      });
    })
  })
  .catch(handleCatch);
}

function handleLoginResponseWithCode(response){
  if (!response.ok) {
    if(response.status === 401){
      return response.json();
    }
    if(response.status === 404){
      return Promise.reject('H401');
    }
    return Promise.reject(response.statusText);
  }
  return response.json();
}
