import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './app';
import * as serviceWorker from '../../serviceWorker';
import { initialiseSmartlook } from '../../lib/smartlookFunctions';
//TODO import { loadScripts } from '../universal/lib/simpletools';

// These libraries are defined in /public/lib and loaded in /index.html
if(window.initialiseNewRelic && appConfig.newRelic) {
  window.initialiseNewRelic(appConfig.newRelic);
}

if(window.initialiseFullStory && appConfig.fullStory) {
  window.initialiseFullStory(appConfig.fullStory);
}

if (appConfig.smartlook) { initialiseSmartlook(); }

// if(appConfig.appcues === true) {
// TODO  loadScripts([{id: 'appcues', src: '//fast.appcues.com/92559.js'}])
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
