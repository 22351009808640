import { userConstants, deviceConstants, boardConstants, binderConstants, customerConstants, companyConstants } from '@constants/admin/';
import { UserTypeEnum } from '@constants/common.constants';
import {
  BLANK_GUID,
} from '../../lib/simpletools';
import moment from 'moment';

//import {NamesStorage} from '../lib/indexeddb';
export var REDUCE_INITIAL = {
  loading: false,
  userLimit: undefined,
  groups: undefined,
  groupsLoading: false,
}

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
const initialState = userCache ?
  Object.assign({ customer: {}, data:{}, person: {}})
  :
  Object.assign({customer: {}, data:{}, person: {}})

export var USER_INITIAL_STATE = {
  id:"",
  imageId: "",
  loading: true,
  error:"",

  personId: '',
  firstName: '',
  lastName: '',
  username: '',
  alias:'',
  email: '',
  role: '',
  mobile: '',
  companyEmployeeId: '',
  type: '',
  function: '',
  registrationCode: '',
  deviceIds: null,
  deviceIdsLastUpdate: null,
  recoveryCards: null,
  recoveryCardsLastUpdate: null,
  attendeeIds: [],
  recipientIds: [],
  settings: null,
  key: null,
  hasRegistered: false,
  isDeleted: false,
  customerId: '',
  binders: null,
  bindersLoading: false,
  isInvitedUser: false,
};

export var TASK_INITIAL = {
  jobStep: -1,
  jobStepCurrent: 0,
  jobStepTotal: 0,
  jobTodo: [],
  jobCompleted: [],
  files: false,
  binder: false,
  start: false,
  error: "",
  customerId: "",
  type: "",
  dataId: "",
  userId: "",
  dateCreated: "",
  dateCompleted: "",
  dateLocked: "",
  completedByUserId: "",
  lockedByUserId: "",
  metadata: {},
  newTask: false,
}

function SetUser(state, UserId){
  if(state.data === undefined) state.data = {};
  if(state.data[UserId] === undefined)
    state.data[UserId] = Object.assign({}, USER_INITIAL_STATE);
  state.data[UserId].id = UserId;
  state.data[UserId].loading = true;
//  state.data[UserId].isInvitedUser = false;
  state.data[UserId].checkUser = undefined
  state.data[UserId].error = '';
  return state;
}

function SaveUser(state, userId, customerId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);
  state.data[userId].id = userId;
  state.data[userId].loading = true;
  state.data[userId].saving = true;
  state.data[userId].error = '';

  state = updateCache(state, {error: "", saving: true}, customerId)
  return state
}

function FailedUser(state, userId, error, customerId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);
  state.data[userId].id = userId;
  state.data[userId].loading = false;
  state.data[userId].saving = true;
  state.data[userId].error = error;

  state = updateCache(state, {error: error, saving: false, loading: false}, customerId)
  return state
}

function UpdateUser(state, item, customerId = ""){
  if(item === undefined) return state;
  if(!item.hasOwnProperty('id')) return state;

  if(state.data === undefined) state.data = {};
  if(state.data[item.id] === undefined)
    state.data[item.id] = Object.assign({}, USER_INITIAL_STATE);

  state.data[item.id].error = "";
  state.data[item.id].loading = false;
  state.data[item.id].saving = false;

  state.data[item.id].id = item.id;
  if(item.hasOwnProperty('firstName'))
    state.data[item.id].firstName = item.firstName;
  if(item.hasOwnProperty('lastName'))
    state.data[item.id].lastName = item.lastName;
  if(item.hasOwnProperty('username'))
    state.data[item.id].username = item.username;
  if(item.hasOwnProperty('imageId'))
    if(item.imageId === BLANK_GUID)
      state.data[item.id].imageId = "";
    else
      state.data[item.id].imageId = item.imageId;
  if(item.hasOwnProperty('email'))
    state.data[item.id].email = item.email;
  if(item.hasOwnProperty('mobile'))
    state.data[item.id].mobile = item.mobile;
  if(item.hasOwnProperty('companyEmployeeId'))
    state.data[item.id].companyEmployeeId = item.companyEmployeeId;
  else if(state.data[item.id].companyEmployeeId !== "")
    state.data[item.id].companyEmployeeId = "";
  if(item.hasOwnProperty('type'))
    state.data[item.id].type = item.type;
  if(item.hasOwnProperty('function'))
    state.data[item.id].function = item.function;
  if(item.hasOwnProperty('deviceIds'))
    state.data[item.id].deviceIds = item.deviceIds;
  if(item.hasOwnProperty('attendeeIds'))
    state.data[item.id].attendeeIds = item.attendeeIds;
  if(item.hasOwnProperty('recipientIds'))
    state.data[item.id].recipientIds = item.recipientIds;
  if(item.hasOwnProperty('customerId'))
    state.data[item.id].customerId = item.customerId;
  if(item.hasOwnProperty('settings'))
    state.data[item.id].settings = item.settings;
  if(item.hasOwnProperty('kUserPublic'))
    state.data[item.id].key = item.kUserPublic;
  if(item.hasOwnProperty('hasRegistered'))
    state.data[item.id].hasRegistered = item.hasRegistered;
  if(item.hasOwnProperty('isDeleted'))
    state.data[item.id].isDeleted = item.isDeleted;
  if(item.hasOwnProperty('isInvitedUser'))
    state.data[item.id].isInvitedUser = item.isInvitedUser;
  if(item.hasOwnProperty('supportsDirectInvite'))
    state.data[item.id].supportsDirectInvite = item.supportsDirectInvite;
  if(item.hasOwnProperty('postRegistrationPersonId'))
    state.data[item.id].postRegistrationPersonId = item.postRegistrationPersonId;
  if(item.hasOwnProperty('temporaryPasswordExpiryDate'))
    state.data[item.id].temporaryPasswordExpiryDate = item.temporaryPasswordExpiryDate;
  if(item.hasOwnProperty('temporaryPasswordDate'))
    state.data[item.id].temporaryPasswordDate = item.temporaryPasswordDate;
  if(item.hasOwnProperty('registrationCode'))
    state.data[item.id].registrationCode = item.registrationCode;
  if(item.hasOwnProperty('alias'))
    state.data[item.id].alias = item.alias;
  if (item.hasOwnProperty('error'))
    state.data[item.id].error = item.error;

  if (item.hasOwnProperty('requiresConflictOfInterestDeclaration')) {
    state.data[item.id].requiresConflictOfInterestDeclaration = item.requiresConflictOfInterestDeclaration;
  }

  if (item.hasOwnProperty('role'))
    state.data[item.id].role = item.role;

  if (item.hasOwnProperty('dateMigratedToAnsaradaIdentity')) {
    state.data[item.id].dateMigratedToAnsaradaIdentity = item.dateMigratedToAnsaradaIdentity;
  }
  if (item.hasOwnProperty('isAnsaradaUser')) {
    state.data[item.id].isAnsaradaUser = item.isAnsaradaUser;
  }

  if(item.hasOwnProperty('personId')){
    var personId = item.personId;
    if(item.hasOwnProperty('postRegistrationPersonId')){
      personId = item.postRegistrationPersonId;
    }
    state.data[item.id].personId = personId;

    if(state.person === undefined) state.person = {}
    if(state.person[personId] === undefined) state.person[personId] = []
    if(state.person[personId].find(o => o.userId === item.id) === undefined){
      state.person[personId].push({
        userId: item.id,
        customerId: item.customerId!==undefined?item.customerId:'',
        type: item.type!==undefined?item.type:'',
      })
    }
  }
  if(item.hasOwnProperty('lockpass')){
    state.data[item.id].lockpass = item.lockpass
  }
  if(customerId !== ""){
    state = updateCache(state, {error: "", saving: false}, customerId)
  }
//  Object.keys(item).map(e => state.data[item.id][e] = item[e]);
  state.data[item.id] = JSON.parse(JSON.stringify(state.data[item.id]));

  return state;
}

function deleteUserSettings(state, userId) {
  var state = { ...state };
  state.data = { ...state.data };
  if (state.data && state.data[userId]) {
    state.data[userId].settings = null;
  }

  return state;
}
function updateUserSettings(state, userId, newUserSettings) {
  var state = { ...state };
  state.data = { ...state.data };
  if (state.data && state.data[userId]) {
    state.data[userId].settings = newUserSettings;
  }

  return state;
}

function PopulateUser(state, item){
  if(item === undefined) return state;
  if(item.id === undefined || item.id === '') return state;

  if(state.data === undefined) state.data = {};
  if(state.data[item.id] === undefined)
    state.data[item.id] = Object.assign({}, USER_INITIAL_STATE);

  state.data[item.id] = Object.assign({}, state.data[item.id], item);
  
  if(item.clearUser) {
    delete state.data[item.id];
  } 
    
  return state
}

function InitialUserKey(state, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].key = '';

  return state;
}

function SetUserKey(state, userId, item){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].key = item;

  return state;
}

function SetErrorUser(state, UserId, error){
  if(state.data !== undefined){
    if(state.data[UserId] !== undefined){
      state.data[UserId].loading = false;
      state.data[UserId].error = error;
    }
  }
  return state;
}

function resetUserList(state, customerId){
  if(state.customer === undefined){
    state.customer = {}
  }
  if(state.customer[customerId] === undefined){
    state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  state.customer[customerId].error = ""
  state.customer[customerId].loading = true

//TODO REMOVE
  // if(state.customerId === "" || state.customerId === customerId){
  //   state.error = state.customer[customerId].error
  //   state.loading = state.customer[customerId].loading
  //   state.listAdminIds = state.customer[customerId].listAdminIds
  // }
  return state
}

function UpdateUserList(state, action){
  if(state.customer[action.customerId] === undefined){
    state.customer[action.customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  if(action.items.userIds !== undefined){
    state.customer[action.customerId].listofUser = action.items.userIds
    if(state.customer[action.customerId].listofUser.indexOf(action.items.mainUserId) === -1){
      state.customer[action.customerId].listofUser.push(action.items.mainUserId)
    }
  }
  state.customer[action.customerId].loading = false
  state.latestAction = new Date()

//TODO REMOVE
  // if(state.customerId === "" || state.customerId === action.customerId){
  //   state.listofUser = state.customer[action.customerId].listofUser
  //   state.adminUserId = state.customer[action.customerId].adminUserId
  //   state.companyName = state.customer[action.customerId].companyName
  //   state.loading = state.customer[action.customerId].loading
  // }

  return state;
}

function failUserList(state, action){
  if(state.customer[action.customerId] === undefined){
    state.customer[action.customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  state.customer[action.customerId].error = action.error
  state.customer[action.customerId].loading = false

//TODO REMOVE
  // if(state.customerId === "" || state.customerId === action.customerId){
  //   state.error = state.customer[action.customerId].error
  //   state.loading = state.customer[action.customerId].loading
  // }

  return state
}

function PopulateList(state, items, customerId, loading = false){
  var list = [], listAdminIds = []
  const c = state.customer[customerId]
  for(var x=0; x<items.length; x++){
    if(items[x].type ===UserTypeEnum.Create || items[x].type ===UserTypeEnum.Publish){
      listAdminIds.push(items[x].id)
    }
    state = UpdateUser(state, items[x])
    if(c.listofUser !== undefined && c.listofUser.indexOf(items[x].id) === -1){
      c.listofUser.push(items[x].id)
    }
    list.push({id: items[x].id, firstName: items[x].firstName, lastName: items[x].lastName})
  }

  if(listAdminIds.length > 0){
    c.listAdminIds = listAdminIds
  }else if(c.listAdminIds === undefined) c.listAdminIds = []
  c.loading = loading

  //TODO Remove
  // if(state.customerId === "" || state.customerId === customerId){
  //   state.listofUser = state.customer[customerId].listofUser
  //   state.listAdminIds = state.customer[customerId].listAdminIds
  //   state.loading = false
  // }

  return state
}

function PopulateUserList(state, items){
  state = UpdateUser(state, items.mainUser);
  var listAdminIds = []

  if(state.customer[items.id] === undefined){
    state.customer[items.id] = Object.assign({}, REDUCE_INITIAL)
  }

  state.customer[items.id].adminUserId = items.mainUser.id
  state.customer[items.id].listofUser = [items.mainUser.id]
  state.customer[items.id].userLimit = items.userLimit
  state.customer[items.id].adminUserLimit = items.adminUserLimit
  state.customer[items.id].loading = false

  var list = []
  for(var x=0; x<items.users.length; x++){
    if(items.users[x].type ===UserTypeEnum.Create || items.users[x].type ===UserTypeEnum.Publish){
      listAdminIds.push(items.users[x].id)
    }
    state = UpdateUser(state, items.users[x])
    if(state.customer[items.id].listofUser.indexOf(items.users[x].id) === -1){
      state.customer[items.id].listofUser.push(items.users[x].id)
    }
    list.push({id: items.users[x].id, firstName: items.users[x].firstName, lastName: items.users[x].lastName})
  }

  if(listAdminIds.length > 0){
    state.customer[items.id].listAdminIds = listAdminIds
  }else if(state.customer[items.id].listAdminIds === undefined) state.customer[items.id].listAdminIds = []
  state.customer[items.id].loading = false

//TODO Remove
  // if(state.customerId === "" || state.customerId === items.id){
  //   state.adminUserId = items.mainUser.id
  //   state.companyName = items.name
  //   state.userLimit = items.userLimit
  //   state.adminUserLimit = items.adminUserLimit
  //   state.listofUser = state.customer[items.id].listofUser
  //   state.listAdminIds = state.customer[items.id].listAdminIds
  //   state.loading = false
  // }

  return state;
}

function SetPendingPassword(state, item, customerId){
  if(state.customer[customerId] === undefined){
    state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  state.customer[customerId].pendingRequest = item
//TODO Remove
  // if(state.customerId === "" || state.customerId === customerId){
  //   state.pendingRequest = state.customer[customerId].pendingRequest
  // }

  return state;
}

function DeleteMultiUserReq(state, userIds, customerId){
  userIds.forEach((userId)=>{
    state = DeleteUserReq(state, userId, customerId)
  })

  return state
}

function DeleteUserReq(state, id, customerId){
  //state = DeleteUser(state, id, customerId)

  state.customer[customerId].deleteloading = true

  if(state.data[id] !== undefined){
    state.data[id].isDeleted = true
    state.data[id].loading = false
  }
  return state
}

function DeleteMultiUser(state, userIds, customerId){
  userIds.forEach((userId)=>{
    state = DeleteUser(state, userId, customerId)
  })

  return state
}

function DeleteUser(state, id, customerId){
  if(state.customer[customerId] === undefined){
    state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  if(state.customer[customerId].listofUser !== undefined &&
      state.customer[customerId].listAdminIds !== undefined){
    var index = state.customer[customerId].listofUser.indexOf(id)
    if (index > -1) {
      state.customer[customerId].listofUser.splice(index, 1)
    }
    index = state.customer[customerId].listAdminIds.indexOf(id)
    if (index > -1) {
      state.customer[customerId].listAdminIds.splice(index, 1)
    }

    state.customer[customerId].deleteloading = false
  }

  if(state.data[id] !== undefined){
    state.data[id].isDeleted = true
    state.data[id].loading = false
  }

  return state;
}

function DeleteUserFail(state, customerId){
  if(state.customer[customerId] === undefined){
    state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
  }

  state.customer[customerId].deleteloading = false

  return state
}

function clearNewTask(state){
  state.latestAction = new Date()
  if(state.taskList === undefined){
    return state
  }

  state.taskListNew = false;
  for(var key in state.taskList){
    state.taskList[key].newTask = false
  }

  return state
}

function populateTaskList(state, task){
  var newTask = true;
  if(state.taskList === undefined){
    state.taskList = {};
    newTask = false;
  }
  //remove any task
  var l = task.map(e => e.id)
  for(var key in state.taskList) {
    if(state.taskList[key].type === "internalBinders") continue
    if(l.indexOf(key) === -1){
      delete state.taskList[key];
    }
  }

  var taskCount = 0;
  task.forEach(function(item){
    if(state.taskList[item.id] === undefined){
      state.taskList[item.id] = Object.assign({}, TASK_INITIAL);
      state.taskList[item.id].jobTodo = [];
      state.taskList[item.id].jobCompleted = [];
      if(newTask && !state.taskList[item.id].newTask){
        taskCount++;
        state.taskList[item.id].newTask = true
      }else if(state.taskList[item.id].newTask){
        taskCount++;
      }
    }

    state.taskList[item.id].id = item.id;
    state.taskList[item.id].customerId = item.customerId;
    state.taskList[item.id].type = item.type;
    state.taskList[item.id].dataId = item.dataId;
    state.taskList[item.id].dateCreated = item.dateCreated;
    if(item.hasOwnProperty('dateCompleted')) state.taskList[item.id].dateCompleted = item.dateCompleted;
    if(item.hasOwnProperty('completedByUserId')) state.taskList[item.id].completedByUserId = item.completedByUserId;
    if(item.hasOwnProperty('lockedByUserId')) state.taskList[item.id].lockedByUserId = item.lockedByUserId;
    if(item.hasOwnProperty('userId')) state.taskList[item.id].userId = item.userId;
    if(item.hasOwnProperty('dateLocked')) state.taskList[item.id].dateLocked = item.dateLocked;

    if(item.hasOwnProperty('metadata')){
      try{
        state.taskList[item.id].metadata = JSON.parse(item.metadata);
      }catch(e){
        state.taskList[item.id].metadata = {failed: true};
      }
    }
  })

  state.taskListNew = taskCount;

  return state;
}

function updateTask(state, taskItem){
  if(state.taskList === undefined) state.taskList = {};
  if(taskItem.hasOwnProperty('id')){
    if(state.taskList[taskItem.id] === undefined){
      state.taskList[taskItem.id] = Object.assign({}, TASK_INITIAL);
      state.taskList[taskItem.id].id = taskItem.id;
      state.taskList[taskItem.id].jobTodo = [];
      state.taskList[taskItem.id].jobCompleted = [];
    }

    if(taskItem.hasOwnProperty('customerId')) state.taskList[taskItem.id].customerId = taskItem.customerId;
    if(taskItem.hasOwnProperty('type')) state.taskList[taskItem.id].type = taskItem.type;
    if(taskItem.hasOwnProperty('dataId')) state.taskList[taskItem.id].dataId = taskItem.dataId;
    if(taskItem.hasOwnProperty('userId')) state.taskList[taskItem.id].userId = taskItem.userId;
    if(taskItem.hasOwnProperty('dateCreated')) state.taskList[taskItem.id].dateCreated = taskItem.dateCreated;
    if(taskItem.hasOwnProperty('dateCompleted')) state.taskList[taskItem.id].dateCompleted = taskItem.dateCompleted;
    if(taskItem.hasOwnProperty('dateLocked')) state.taskList[taskItem.id].dateLocked = taskItem.dateLocked;
    if(taskItem.hasOwnProperty('completedByUserId')) state.taskList[taskItem.id].completedByUserId = taskItem.completedByUserId;
    if(taskItem.hasOwnProperty('lockedByUserId')) state.taskList[taskItem.id].lockedByUserId = taskItem.lockedByUserId;
    if(taskItem.hasOwnProperty('files')) state.taskList[taskItem.id].files = taskItem.files;
    if(taskItem.hasOwnProperty('binder')) state.taskList[taskItem.id].binder = taskItem.binder;
    if(taskItem.hasOwnProperty('start')) state.taskList[taskItem.id].start = taskItem.start;

    if(taskItem.hasOwnProperty('jobTodo')){
      taskItem.jobTodo.forEach(function(id){
        if(state.taskList[taskItem.id].jobTodo.indexOf(id) === -1){
          state.taskList[taskItem.id].jobTodo.push(id);
        }
      });
    }
    if(taskItem.hasOwnProperty('jobCompleted')){
      taskItem.jobCompleted.forEach(function(id){
        if(state.taskList[taskItem.id].jobCompleted.indexOf(id) === -1){
          state.taskList[taskItem.id].jobCompleted.push(id);
        }
      });
    }
  }

  return state;
}

function stepTask(state, item){
  if(state.taskList[item.id] !== undefined){
    if(item.step !== undefined){
      state.taskList[item.id].jobStep = item.step;
      state.taskList[item.id].jobStepCurrent = 0;
      state.taskList[item.id].jobStepTotal = 0;
    }
    if(item.stepTotal !== undefined){
      state.taskList[item.id].jobStepTotal = item.stepTotal;
    }
    if(item.stepTotalAppend !== undefined){
      state.taskList[item.id].jobStepTotal += item.stepTotalAppend;
    }
    if(item.stepIncrement !== undefined){
      state.taskList[item.id].jobStepCurrent++;
    }
    if(item.stepCurrent !== undefined){
      state.taskList[item.id].jobStepCurrent = item.stepCurrent;
    }
  }
  return state;
}

function completeTask(state, id){
  if(state.taskList[id] !== undefined){
    state.taskList[id].jobStep = 3;
    state.taskList[id].jobStepCurrent = 0;
    state.taskList[id].jobStepTotal = 0;
    state.taskList[id].start = false;
  }
  return state;
}

function errorTaskList(state, id, error){
  if(state.taskList !== undefined){
    if(state.taskList[id] !== undefined){
      state.taskList[id].error = error;
      state.taskList[id].start = false;
    }
  }

  return state;
}

function removeTask(state, id){
  if(state.taskList === undefined) state.taskList = {};
  if(state.taskList[id] !== undefined){
    //if(state.taskList[id].type === "internalBinders"){
      state.taskList[id].dateCompleted = moment(new Date()).utc().format()
      state.taskList[id].completedByUserId = state.taskList[id].lockedByUserId
      state.taskList[id].start = false;
    //}else
    //  delete state.taskList[id];
  }
  return state;
}

function RequestUserCardList(state, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].recoveryCards = null;
  state.data[userId].recoveryCardsLastUpdate = true;
  return state;
}

function UpdateUserCardList(state, userId, payload){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].recoveryCards = payload;
  state.data[userId].recoveryCardsLastUpdate = new Date();
  return state;
}

function UpdateUserVariable(state, userId, name, payload){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId][name] = payload;
  return state;
}

function RequestUserDevice(state, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].deviceIds = null;
  state.data[userId].deviceIdsLastUpdate = true;
  return state;
}

function UpdateUserDevice(state, userId, payload){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  var a = [];
  payload.forEach(function(item){
    a.push(item.id)
  })
  state.data[userId].deviceIds = a;
  state.data[userId].deviceIdsLastUpdate = new Date();
  return state;
}

function clearGenPassStatus(state, id, customerId){
  if(customerId !== "" && state.customer[customerId] !== undefined){
    if(state.customer[customerId].genpassstatus !== undefined){
      if(state.customer[customerId].genpassstatus.id !== undefined){
        if(state.customer[customerId].genpassstatus.id === id &&
          (state.customer[customerId].genpassstatus.status === 'error' ||
           state.customer[customerId].genpassstatus.status === 'passfail' ||
           state.customer[customerId].genpassstatus.status === 'signfail' ||
           state.customer[customerId].genpassstatus.status ===  'fail'
            )){
          state.customer[customerId].genpassstatus = {status: ''};
        }
      }
    }
    state.customer[customerId].listOfUserName = undefined
  }

//TODO Remove
  // if(state.genpassstatus !== undefined){
  //   if(state.genpassstatus.id !== undefined){
  //     if(state.genpassstatus.id === id &&
  //       (state.genpassstatus.status === 'error' ||
  //        state.genpassstatus.status === 'passfail' ||
  //        state.genpassstatus.status === 'signfail' ||
  //        state.genpassstatus.status ===  'fail'
  //         )){
  //       state.genpassstatus = {status: ''};
  //     }
  //   }
  // }

  return state;
}

function intitalGroup(state, customerId){
  if(customerId !== "" && state.customer[customerId] !== undefined){
    if(state.customer[customerId].groups === undefined){
      state.customer[customerId].groups = {};
    }
    state.customer[customerId].groupsLoading = true;
  }
//TODO Remove
  // if(state.groups === undefined){
  //   state.groups = {};
  // }
  // state.groupsLoading = true;

  return state;
}

function setGroup(state, payload, customerId){
  var groups = null
  if(customerId !== "" && state.customer[customerId] !== undefined){
    if(state.customer[customerId].groups !== undefined){
      groups = state.customer[customerId].groups
    }
  }
  if(groups === null){
    if(state.groups === undefined)
      groups = {}
    else groups = state.groups
  }

  if(groups[payload.id] === undefined)
    groups[payload.id] = {
      id: payload.id,
      name: '',
      userIds: [],
    };

  groups[payload.id].id = payload.id;
  if(payload.hasOwnProperty('customerId')){
    if(customerId === "") customerId = payload.customerId
    groups[payload.id].customerId = payload.customerId
  }
  if(payload.hasOwnProperty('name'))
    groups[payload.id].name = payload.name;
  if(payload.hasOwnProperty('modificationDate'))
    groups[payload.id].modificationDate = payload.modificationDate;
  if(payload.hasOwnProperty('userIds'))
    groups[payload.id].userIds = payload.userIds;
  if(payload.hasOwnProperty('appendUserIds')){
    payload.appendUserIds.forEach(function(userId){
      var index = groups[payload.id].userIds.indexOf(userId);
      if(index === -1){
        groups[payload.id].userIds.push(userId)
      }
    })
  }
  if(payload.hasOwnProperty('removeUserIds')){
    payload.removeUserIds.forEach(function(userId){
      var index = groups[payload.id].userIds.indexOf(userId);
      if(index !== -1){
        groups[payload.id].userIds.splice(index, 1)
      }
    })
  }

  if(customerId !== ""){
    if(state.customer[customerId] === undefined)
      state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
    state.customer[customerId].groups = groups
    state.customer[customerId].groupsLoading = false;
  }
//TODO Remove
  // state.groups = groups

  return state;
}

function setGroups(state, payload, customerId){
  payload.forEach(function(item){
    state = setGroup(state, item, customerId);
  })

  if(customerId !== "" && state.customer[customerId] !== undefined){
    state.customer[customerId].groupsLoading = false
  }

  //state.groupsLoading = false

  return state;
}

function deleteGroup(state, id, customerId){
  if(customerId !== "" && state.customer[customerId] !== undefined){
    if(state.customer[customerId].groups === undefined){
      state.customer[customerId].groups = {}
    }

    if(state.customer[customerId].groups[id] !== undefined)
      delete state.customer[customerId].groups[id];
  }
//TODO Remove
  // if(state.groups === undefined){
  //   state.groups = {};
  // }
  //
  // if(state.groups[id] !== undefined)
  //   delete state.groups[id];

  return state;
}

function loadingUserBoards(state, boardId, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].bindersLoading = true;

  return state;
}

function populateUserBoards(state, boardId, userId, payload){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  if(state.data[userId].binders === null)
    state.data[userId].binders = {}

  if(state.data[userId].binders[boardId] === undefined)
    state.data[userId].binders[boardId] = {}
  state.data[userId].binders[boardId].current = {};
  payload.Current.forEach(function(obj){
    state.data[userId].binders[boardId].current[obj.id] = obj;
  });
  state.data[userId].binders[boardId].previous = {};
  payload.Previous.forEach(function(obj){
    state.data[userId].binders[boardId].previous[obj.id] = obj;
  });
  state.data[userId].binders[boardId].archive = {};
  payload.Archive.forEach(function(obj){
    state.data[userId].binders[boardId].archive[obj.id] = obj;
  });

  state.data[userId].bindersLoading = false;

  return state;
}

function loadingUserBinder(state, boardId, binderId, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  if(state.data[userId].binders === null)
    state.data[userId].binders = {}

  if(state.data[userId].binders[boardId] === undefined)
    state.data[userId].binders[boardId] = {}

  if(state.data[userId].binders[boardId].current === undefined)
    state.data[userId].binders[boardId].current = {};
  if(state.data[userId].binders[boardId].previous === undefined)
    state.data[userId].binders[boardId].previous = {};
  if(state.data[userId].binders[boardId].archive === undefined)
    state.data[userId].binders[boardId].archive = {};

  if(state.data[userId].binders[boardId] === undefined)
    state.data[userId].binders[boardId] = {}
  if(state.data[userId].binders[boardId].current[binderId] !== undefined){
    state.data[userId].binders[boardId].current[binderId].loading = true;
  }else if(state.data[userId].binders[boardId].previous[binderId] !== undefined){
    state.data[userId].binders[boardId].previous[binderId].loading = true;
  }else if(state.data[userId].binders[boardId].archive[binderId] !== undefined){
    state.data[userId].binders[boardId].archive[binderId].loading = true;
  }

  return state;
}

function populateUserBinder(state, boardId, binderId, userId, item){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  if(state.data[userId].binders === null)
    state.data[userId].binders = {}

  if(state.data[userId].binders[boardId] === undefined)
    state.data[userId].binders[boardId] = {}
  if(state.data[userId].binders[boardId].current[binderId] !== undefined){
    state.data[userId].binders[boardId].current[binderId] = item;
    state.data[userId].binders[boardId].current[binderId].loading = false;
  }else if(state.data[userId].binders[boardId].previous[binderId] !== undefined){
    state.data[userId].binders[boardId].previous[binderId] = item;
    state.data[userId].binders[boardId].previous[binderId].loading = false;
  }else if(state.data[userId].binders[boardId].archive[binderId] !== undefined){
    state.data[userId].binders[boardId].archive[binderId] = item;
    state.data[userId].binders[boardId].archive[binderId].loading = false;
  }

  return state;
}

function loadingUserFiles(state, boardId, userId){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  state.data[userId].filesLoading = true;

  return state;
}

function populateUserFiles (state, boardId, userId, payload){
  if(state.data === undefined) state.data = {};
  if(state.data[userId] === undefined)
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);

  if(state.data[userId].files === null)
    state.data[userId].files = {}

    state.data[userId].files = payload;
  state.data[userId].filesLoading = false;

  return state;
}

function populateAuditLogs(state, doneItem){
  if(doneItem.customers !== undefined){
    doneItem.customers.forEach(function(c){
      state = PopulateUserList(state, c);
    })
  }

  return state;
}

function myUsersList(state, items){
  if(items === undefined) return state
  for(var x=0; x<items.length; x++){
    state = UpdateUser(state, items[x]);
  }

  return state
}

function deletePerson(state, personId){
  var users = null
  if(state.person !== undefined){
    if(state.person[personId] !== undefined){
      users = state.person[personId].slice(0)
      delete state.person[personId]
    }
  }

  if(users !== null){
    users.forEach((u)=>{
      if(state.data[u.userId] !== undefined){
        state.data[u.userId].isDeleted = true
      }
    })
  }

  return state
}

function updateCache(state, obj, customerId = ""){
  if(customerId !== ""){
    if(state.customer[customerId] === undefined){
      state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
    }
    state.customer[customerId] = Object.assign(state.customer[customerId], obj)
  }

  state.latestAction = new Date()

//TODO Remove
  //state = Object.assign(state, obj)

  return state
}

/*function updateWelPdf(state, id, pdf){
  var customerId = state.customerId

  if(customerId !== ""){
    if(state.customer[customerId] === undefined){
      state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
    }
    state.customer[customerId] = Object.assign(state.customer[customerId], {genpassstatus: {status: 'blob', id: id, blob: pdf}})
  }

//TODO Remove
  //state = Object.assign(state, {genpassstatus: {status: 'blob', id: id, blob: pdf}})

  if(state.data[id] !== undefined){
    state.data[id].welcomepdf = pdf
  }

  return state
}*/

function sendUserEmail(state, item, value, customerId){
  var emailFailure = value
  if(value === true){
    emailFailure = false
  }else if(value === false){
    emailFailure = true
  }

  if(customerId !== ""){
    if(state.customer[customerId] === undefined){
      state.customer[customerId] = Object.assign({}, REDUCE_INITIAL)
    }
    state.customer[customerId] = Object.assign(state.customer[customerId], {emailFailure: emailFailure})
  }

  state.latestAction = new Date()

  if(state.data[item.id] !== undefined){
    state.data[item.id].emailSending = value
  }

  return state
}

function setTempData(state, item, action){
  if(state.taskList === undefined) state.taskList = {};
  if(state.taskList[item.taskId] === undefined){
    state.taskList[item.taskId] = Object.assign({}, TASK_INITIAL);
    state.taskList[item.taskId].id = item.id;
    state.taskList[item.taskId].jobTodo = [];
    state.taskList[item.taskId].jobCompleted = [];
  }

  var customer = null
  if(item.customerId !== undefined && state.customer[item.customerId] !== undefined)
    customer = state.customer[item.customerId]
  if(customer !== null){
    if(action === null){
      customer.genpassstatus = {status: 'newDevice', id: item.userId}
      state.taskList[item.taskId].error = undefined
      state.taskList[item.taskId].code = undefined
    }else if(item.error !== undefined){
      customer.genpassstatus = {status: 'fail', id: item.userId}
      customer.error = action.error
      state.taskList[item.taskId].error = action.error
    }else{
      customer.genpassstatus = {status: 'approveDevice', id: item.userId, code: action.twoFactorAuthCode}
      state.taskList[item.taskId].code = action.twoFactorAuthCode
      state.taskList[item.taskId].start = false
    }
  }

  state.latestAction = new Date()

  return state
}

/*function resetData(state, customerId){
  state.loading = false
  state.groupsLoading = false

  if(state.customer[customerId] !== undefined){
    state.adminUserId = state.customer[customerId].adminUserId
    state.companyName = state.customer[customerId].companyName
    state.userLimit = state.customer[customerId].userLimit
    state.adminUserLimit = state.customer[customerId].adminUserLimit
  }else{
    state.adminUserId = undefined
    state.companyName = ""
    state.userLimit = undefined
    state.adminUserLimit = undefined
  }
  state.customerId = customerId
  return state
}*/

function boardCheck(state, boardId, userId, status){
  if(state.data[userId] !== undefined){
    if(state.data[userId].checkBoards === undefined)
      state.data[userId].checkBoards = {}

    if(status !== null && status !== false && status !== true){
      if(status.find(o => o === userId))
        state.data[userId].checkBoards[boardId] = false
      else
        state.data[userId].checkBoards[boardId] = true
    }else
      state.data[userId].checkBoards[boardId] = status
  }
  return state
}

function checkAlias(state, result, userId){
  if(state.data[userId] === undefined){
    state.data[userId] = Object.assign({}, USER_INITIAL_STATE);
    state.data[userId].id = userId
  }

  if(userId !== "" && state.data[userId] !== undefined){
    state.data[userId].checkAlias = result
  }

  //state.checkAlias = result

  return state
}

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return Object.assign({}, resetUserList(state, action.customerId));
    case userConstants.GETALL_SUCCESS:
      return Object.assign({}, UpdateUserList(state, action), {latestAction: new Date()});
    case userConstants.GETALL_FAILURE:
      return Object.assign({}, failUserList(state, action));
    case userConstants.POPULATEALL_USERS_REQUEST:
      return Object.assign({}, resetUserList(state, action.customerId), {latestAction: new Date()});
    case userConstants.POPULATEALL_USERS_SUCCESS:
      return Object.assign({}, PopulateUserList(state, action.items), {latestAction: new Date()});
    case userConstants.POPULATEALL_USERS_FAILURE:
      return Object.assign({}, failUserList(state, action.customerId), {latestAction: new Date()});
    case userConstants.POPULATE_USERS_REQUEST:
      return Object.assign({}, resetUserList(state, action.customerId));
    case userConstants.POPULATE_USERS_UPDATE:
      return Object.assign({}, PopulateList(state, action.items, action.customerId, true), {latestAction: new Date()});
    case userConstants.POPULATE_USERS_SUCCESS:
      return Object.assign({}, PopulateList(state, action.items, action.customerId, false), {latestAction: new Date()});
    case userConstants.POPULATE_USERS_FAILURE:
      return Object.assign({}, failUserList(state, action.customerId));

    case userConstants.GETLOGIN_SUCCESS:
      return Object.assign({}, myUsersList(state, action.items), {latestAction: new Date()});

    case userConstants.GETUSER_REQUEST:
      return Object.assign({}, SetUser(state, action.userId));
    case userConstants.DEMO_ADDUSER:
    case userConstants.GETUSER_SUCCESS:
      return Object.assign({}, UpdateUser(state, action.item),{latestAction: new Date()});
    case userConstants.GETUSER_FAILURE:
      return Object.assign({}, SetErrorUser(state, action.userId, action.error));
    case userConstants.GETUSER_PUBLICKEY_REQUEST:
      return Object.assign({}, InitialUserKey(state, action.userId));
    case userConstants.GETUSER_PUBLICKEY_SUCCESS:
      return Object.assign({}, SetUserKey(state, action.userId, action.item.kUser),{latestAction: new Date()});
    case userConstants.GETUSER_PUBLICKEY_FAILURE:
      return Object.assign({}, SetErrorUser(state, action.userId, action.error));
    case userConstants.DELETE_REQUEST:
      return Object.assign({}, DeleteUserReq(state, action.id, action.customerId),{latestAction: new Date()});
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return Object.assign({}, DeleteUser(state, action.id, action.customerId),{latestAction: new Date()});
    case userConstants.DELETE_FAILURE:
      return Object.assign({}, DeleteUserFail(state, action.customerId),{latestAction: new Date()});
    case userConstants.DELETE_MULTI_REQUEST:
      return Object.assign({}, DeleteMultiUserReq(state, action.userIds, action.customerId));
    case userConstants.DELETE_MULTI_SUCCESS:
      // remove deleted user from state
      return Object.assign({}, DeleteMultiUser(state, action.userIds, action.customerId),{latestAction: new Date()});
    case userConstants.DELETE_MULTI_FAILURE:
      return Object.assign({}, DeleteUserFail(state, action.customerId));
    case userConstants.UPDATE_REQUEST:
      return Object.assign({}, SaveUser(state, action.userId, action.customerId), {latestAction: new Date()});
    case userConstants.UPDATE_SUCCESS:
      return Object.assign({}, UpdateUser(state, action.item, action.customerId), {latestAction: new Date()});
    case userConstants.UPDATE_FAILURE:
      return Object.assign({}, FailedUser(state, action.userId, action.error, action.customerId), {latestAction: new Date()});
    case userConstants.NEW_USER_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", loading: true, genpassstatus: {status: ''}}, action.customerId));
    case userConstants.NEW_USER_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false}, action.customerId));
    case userConstants.NEW_USER_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'error'}, loading: false}, action.customerId));
    case userConstants.IMPORT_USER_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", importloading: true, genpassstatus: {status: ''}}, action.customerId));
    case userConstants.IMPORT_ADD_SUCCESS:
      return Object.assign({}, UpdateUser(state, action.payload), {latestAction: new Date()});
    case userConstants.IMPORT_USER_SUCCESS:
      return Object.assign({}, updateCache(state, {importloading: false}, action.customerId));
    case userConstants.IMPORT_USER_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'error'}, importloading: false}, action.customerId));
    case userConstants.INVITE_USER_REQUEST:
      return Object.assign({}, PopulateUser(state, {id: action.userId, error: "", importloading: true}));
    case userConstants.INVITE_USER_SUCCESS:
      return Object.assign({}, PopulateUser(state, {id: action.userId, importloading: false, genpassstatus: {status: 'supportsDirectInvite', id: action.userId}}));
    case userConstants.INVITE_USER_FAILURE:
      return Object.assign({}, PopulateUser(state, {id: action.userId, error: action.error, importloading: false}));

    case userConstants.DELETE_USER_SETTINGS:
      return Object.assign({}, deleteUserSettings(state, action.payload.userId));
    case userConstants.UPDATE_USER_SETTINGS:
      return Object.assign({}, updateUserSettings(state, action.payload.userId, action.payload.newUserSettings));

    case userConstants.INVITE_USERS_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", importloading: true}, action.customerId));
    case userConstants.INVITE_USERS_SUCCESS:
      return Object.assign({}, updateCache(state, {importloading: false}, action.customerId));
    case userConstants.INVITE_USERS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, importloading: false}, action.customerId));

    case userConstants.CHECK_USEREXISTS_REQUEST:
      return Object.assign({}, PopulateUser(state, {id: action.payload.id, loading: true, checkUser: undefined}), {latestAction: new Date()});
    case userConstants.CHECK_USEREXISTS_SUCCESS:
      return Object.assign({}, PopulateUser(state, action.payload), {latestAction: new Date()});
    case userConstants.CHECK_USEREXISTS_FAILURE:
      return Object.assign({}, UpdateUser(state, action.payload), {latestAction: new Date()});
    case userConstants.CLEAR_USEREXISTS_REQUEST:
      return Object.assign({}, PopulateUser(state, {id: action.userId, checkUser: [], email: '', clearUser: true}), {latestAction: new Date()});
    case userConstants.CONFIRM_USEREXISTS_REQUEST:
      return Object.assign({}, PopulateUser(state, {id: action.userId, verifyUser: undefined}), {latestAction: new Date()});

    case userConstants.SEND_WELCOMEEMAIL_REQUEST:
      return Object.assign({}, sendUserEmail(state, action.userItem, null, action.customerId), {latestAction: new Date()});
    case userConstants.SEND_WELCOMEEMAIL_SUCCESS:
      return Object.assign({}, sendUserEmail(state, action.userItem, true, action.customerId), {latestAction: new Date()});
    case userConstants.SEND_WELCOMEEMAIL_FAILURE:
      return Object.assign({}, sendUserEmail(state, action.userItem, false, action.customerId), {latestAction: new Date()});

    case userConstants.APPROVE_ADMINUSER_REQUEST:
    case userConstants.APPROVE_NORMUSER_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", loading: true, genpassstatus: {status: ''}}, action.customerId));
    case userConstants.APPROVE_ADMINUSER_SUCCESS:
    case userConstants.APPROVE_NORMUSER_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false, genpassstatus: {status: 'registered', id: action.id}}, action.customerId));
    case userConstants.APPROVE_ADMINUSER_FAILURE:
      return Object.assign({}, updateCache(errorTaskList(state, action.id, action.error), {error: action.error, genpassstatus: {status: 'error', id: action.id}, loading: false}, action.customerId));

    case userConstants.GET_LIST_PENDING_PASSWORD_REQUEST:
      return Object.assign({}, updateCache(state, {error: ""}, action.customerId))
    case userConstants.GET_LIST_PENDING_PASSWORD_SUCCESS:
      return Object.assign({}, SetPendingPassword(state, action.item, action.customerId));
    case userConstants.GET_LIST_PENDING_PASSWORD_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case userConstants.APPROVE_USER_NEWPASS_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", genpassstatus: {status: 'passreset', id: action.id}}, action.customerId))
    case userConstants.APPROVE_USER_NEWPASS_SUCCESS:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'passdone', id: action.id}}, action.customerId))
    case userConstants.APPROVE_USER_NEWPASS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'passfail', id: action.id}}, action.customerId))
    case userConstants.UPDATE_USER_INITALPASS_REQUEST:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'server'}}, action.customerId))
    //case userConstants.UPDATE_USER_INITALTEMP_REQUEST:
    //  return Object.assign({}, updateCache(state, {genpassstatus: {status: 'temp'}}))
    case userConstants.UPDATE_USER_INITALGEN_REQUEST:
      return Object.assign({}, PopulateUser(state, {id: action.userId, genpassstatus: {status: 'gen'}}))
    case userConstants.UPDATE_USER_INITALPASS_SUCCESS:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'blob', id: action.id, blob: action.pdf}}, action.customerId))
    case userConstants.UPDATE_USER_INITALPASS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'fail'}}, action.customerId))
    case userConstants.UPDATE_USER_INITALCLEAR_REQUEST:
      if(action.userId !== ""){
        state = Object.assign({}, PopulateUser(state, {id: action.userId, error: "", genpassstatus: undefined}))
      }
      return Object.assign({}, updateCache(state, {error: "", genpassstatus: undefined}, action.customerId))
    case userConstants.UPDATE_USER_INITALALIAS_REQUEST:
      return Object.assign({}, PopulateUser(state, { id: action.userId, registrationCode: action.registrationCode, genpassstatus: { status: 'supportsAliasCode', code: action.registrationCode, alias: action.alias, sendEmail: action.sendEmail } }), { latestAction: new Date() })

    case userConstants.USER_WELCOME_PDF_SUCCESS:
      //return Object.assign({}, updateWelPdf(state, action.id, action.pdf), {latestAction: new Date()});
      return state
    case userConstants.LIST_ALL_TASK_REQUEST:
      return Object.assign({}, state);
    case userConstants.LIST_ALL_TASK_SUCCESS:
      return Object.assign({}, populateTaskList(state, action.list), {latestAction: new Date()});
    case userConstants.LIST_ALL_TASK_FAILURE:
      return Object.assign({}, state);

    case userConstants.UPDATE_TASK_REQUEST:
      return Object.assign({}, updateTask(state, action.taskItem), {latestAction: new Date()});

    case userConstants.GET_TASK_REQUEST:
      return Object.assign({}, state);
    case userConstants.GET_TASK_SUCCESS:
      return Object.assign({}, state);
    case userConstants.GET_TASK_FAILURE:
      return Object.assign({}, state);

    case userConstants.CLEAR_NEW_TASK_REQUEST:
      return Object.assign({}, clearNewTask(state))

    case userConstants.COMPLETE_TASK_REQUEST:
      return Object.assign({}, completeTask(state, action.id), {latestAction: new Date()})
    case userConstants.STEP_TASK_REQUEST:
      return Object.assign({}, stepTask(state, action.item), {latestAction: new Date()})

    case userConstants.GET_AVAILABLENAMES_REQUEST:
      return Object.assign({}, updateCache(state, {listOfUserName: null}, action.customerId), {latestAction: new Date()})
    case userConstants.GET_AVAILABLENAMES_SUCCESS:
      return Object.assign({}, updateCache(state, {listOfUserName: action.payload}, action.customerId), {latestAction: new Date()})
    case userConstants.GET_AVAILABLENAMES_FAILURE:
      return Object.assign({}, updateCache(state, {listOfUserName: 'error', error: action.error}, action.customerId), {latestAction: new Date()})

    case userConstants.CHECK_ALIASNAME_REQUEST:
      return Object.assign({}, checkAlias(state, null, action.userId), {latestAction: new Date()})
    case userConstants.CHECK_ALIASNAME_SUCCESS:
      return Object.assign({}, checkAlias(state, action.payload, action.userId), {latestAction: new Date()})
    case userConstants.CHECK_ALIASNAME_FAILURE:
      return Object.assign({}, checkAlias(state, 'error', action.userId), {latestAction: new Date()})

    case userConstants.REGISTER_RECOVERYCARD_REQUEST:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'registering', id: action.id}}, action.customerId))
    case userConstants.REGISTER_RECOVERYCARD_SUCCESS:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'registerComplete', id: action.id}}, action.customerId))
    case userConstants.REGISTER_RECOVERYCARD_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'fail', id: action.id}}, action.customerId))

    case userConstants.GET_USER_SERIALCARD_REQUEST:
      return Object.assign({}, RequestUserCardList(state, action.userId), {latestAction: new Date()});
    case userConstants.GET_USER_SERIALCARD_SUCCESS:
      return Object.assign({}, updateCache(UpdateUserCardList(state, action.userId, action.payload), {loading: false}, action.customerId), {latestAction: new Date()});
    case userConstants.GET_USER_SERIALCARD_FAILURE:
      return Object.assign({}, updateCache(state, {loading: false, error: action.error}, action.customerId));

    case userConstants.GET_USER_DEVICES_REQUEST:
      return Object.assign({}, RequestUserDevice(state, action.userId), {latestAction: new Date()});
    case userConstants.GET_USER_DEVICES_SUCCESS:
      return Object.assign({}, updateCache(UpdateUserDevice(state, action.userId, action.payload), {loading: false}, action.customerId), {latestAction: new Date()});
    case userConstants.GET_USER_DEVICES_FAILURE:
      return Object.assign({}, state);

    case deviceConstants.SET_NEW_DEVICE_REQUEST:
      return Object.assign({}, setTempData(state, action.item, null))
    case deviceConstants.SET_NEW_DEVICE_SUCCESS:
      return Object.assign({}, setTempData(state, action.item, action.payload))
    case deviceConstants.SET_NEW_DEVICE_FAILURE:
      return Object.assign({}, setTempData(state, action.item, action.error))

    case userConstants.REG_SIGNCERT_REQUEST:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'reqsign', id: action.userId}}, action.customerId))
    case userConstants.REG_SIGNCERT_SUCCESS:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'signComplete', id: action.userId}}, action.customerId))
    case userConstants.REG_SIGNCERT_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'signfail', id: action.userId}}, action.customerId))

    case userConstants.LOCAKPASS_PAIRDEVICE_REQUEST:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'registering', id: action.id}}, action.customerId))
    case userConstants.LOCAKPASS_PAIRDEVICE_SUCCESS:
      return Object.assign({}, updateCache(state, {genpassstatus: {status: 'registerComplete', id: action.id}}, action.customerId))
    case userConstants.LOCAKPASS_PAIRDEVICE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, genpassstatus: {status: 'fail', id: action.id}}, action.customerId))

    case userConstants.CHANGE_PASSWORD_INTIALISE:
      return Object.assign({}, updateCache(state, { passChange: undefined }, action.customerId), {latestAction: new Date()})
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return Object.assign({}, updateCache(state, { passChange: "loading" }, action.customerId), {latestAction: new Date()})
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, updateCache(state, { passChange: "success" }, action.customerId), {latestAction: new Date()})
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, updateCache(state, { passChange: "error", error: action.error }, action.customerId), {latestAction: new Date()})

    case userConstants.GROUP_GET_REQUEST:
      return Object.assign({}, intitalGroup(state, action.customerId));
    case userConstants.GROUP_GET_SUCCESS:
      return Object.assign({}, setGroup(state, action.payload, action.customerId), {latestAction: new Date()});
    case userConstants.GROUP_GET_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, latestAction: new Date()}, action.customerId))

    case userConstants.GROUP_GETALL_REQUEST:
      return Object.assign({}, intitalGroup(state, action.customerId));
    case userConstants.GROUP_GETALL_SUCCESS:
      return Object.assign({}, setGroups(state, action.payload, action.customerId), {latestAction: new Date()});
    case userConstants.GROUP_GETALL_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId), {latestAction: new Date()})

    case userConstants.GROUP_UPDATE_REQUEST:
      return Object.assign({}, setGroup(state, action.payload, action.customerId), {latestAction: new Date()});
    case userConstants.GROUP_UPDATE_SUCCESS:
      return Object.assign({}, setGroup(state, action.payload, action.customerId), {latestAction: new Date()});
    case userConstants.GROUP_UPDATE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId), {latestAction: new Date()})

    case userConstants.GROUP_ADD_REQUEST:
      return Object.assign({}, intitalGroup(state, action.customerId));
    case userConstants.GROUP_ADD_SUCCESS:
      return Object.assign({}, setGroup(state, action.payload, action.customerId), {latestAction: new Date()});
    case userConstants.GROUP_ADD_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId), {latestAction: new Date()})

    case userConstants.USER_ANALYTICS_REQUEST:
      return Object.assign({}, UpdateUserVariable(state, action.userId, "analytics", true));
    case userConstants.USER_ANALYTICS_SUCCESS:
      return Object.assign({}, UpdateUserVariable(state, action.userId, "analytics", action.payload));
    case userConstants.USER_ANALYTICS_FAILURE:
      return Object.assign({}, UpdateUserVariable(state, action.userId, "analytics", false));

    case userConstants.GROUP_DELETE_REQUEST:
      return Object.assign({}, state);
    case userConstants.GROUP_DELETE_SUCCESS:
      return Object.assign({}, deleteGroup(state, action.id, action.customerId), {latestAction: new Date()})
    case userConstants.GROUP_DELETE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId), {latestAction: new Date()})

    case userConstants.CLEAR_ERROR_MSG:
      return Object.assign({}, clearGenPassStatus(state, action.id, action.customerId));

    case userConstants.COMPLETE_TASK_SUCCESS:
      return Object.assign({}, removeTask(state, action.taskId !== undefined?action.taskId:action.id), {latestAction: new Date()});

    case userConstants.ERROR_TASK_FAILURE:
      return Object.assign({}, errorTaskList(state, action.taskId, action.error), {latestAction: new Date()});
    case userConstants.RESET_TASK_REQUEST:
      return Object.assign({}, errorTaskList(state, action.taskId, ""), {latestAction: new Date()});

    case userConstants.MANUAL_CONFIRM_INVITE_REQUEST:
      return Object.assign({}, updateCache(state, {loading: false, inviteRequest: true}, action.customerId))
    case userConstants.MANUAL_CONFIRM_INVITE_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false, inviteRequest: "completed"}, action.customerId))
    case userConstants.MANUAL_CONFIRM_INVITE_FAILURE:
      return Object.assign({}, updateCache(state, {loading: false, inviteRequest: "failed"}, action.customerId))
    case userConstants.CLEAR_CONFIRM_INVITE_SUCESS:
      return Object.assign({}, updateCache(state, {loading: false, inviteRequest: undefined}, action.customerId))

    case userConstants.AUTO_INVITE_REQUEST:
      return Object.assign({}, updateCache(state, {loading: false, inviteAuto: true}, action.customerId))
    case userConstants.AUTO_INVITE_UPDATE:
      return Object.assign({}, updateCache(state, {loading: false, inviteAuto: action.value}, action.customerId))
    case userConstants.AUTO_INVITE_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false, inviteAuto: undefined}, action.customerId))
    case userConstants.AUTO_INVITE_FAILURE:
      return Object.assign({}, updateCache(state, {loading: false, inviteAuto: undefined}, action.customerId))

    case userConstants.LOCAKPASS_HASACCOUNT_SUCCESS:
    return Object.assign({}, UpdateUser(state, {id: action.userId, lockpass: action.payload}))

    case userConstants.LOCK_TASK_REQUEST:
      return Object.assign({}, updateTask(state, {id: action.taskId, start: true, lockedByUserId: action.userId}), {latestAction: new Date()})

    case boardConstants.GET_BINDERS_USER_REQUEST:
      return Object.assign({}, loadingUserBoards(state, action.boardId, action.userId), {latestAction: new Date()});
    case boardConstants.GET_BINDERS_USER_SUCCESS:
      return Object.assign({}, populateUserBoards(state, action.boardId, action.userId, action.payload), {latestAction: new Date()});
    case binderConstants.POPULATE_USERBINDERCONTENT_REQUEST:
      return Object.assign({}, loadingUserBinder(state, action.boardId, action.binderId, action.userId), {latestAction: new Date()});
    case binderConstants.POPULATE_USERBINDERCONTENT_SUCCESS:
      return Object.assign({}, populateUserBinder(state, action.boardId, action.binderId, action.userId, action.item), {latestAction: new Date()});
    case boardConstants.GET_FILELIST_USER_REQUEST:
      return Object.assign({}, loadingUserFiles(state, action.boardId, action.userId), {latestAction: new Date()});
    case boardConstants.GET_FILELIST_USER_SUCCESS:
      return Object.assign({}, populateUserFiles(state, action.boardId, action.userId, action.payload), {latestAction: new Date()});

    case companyConstants.AUDIT_POPULATEFILELIST_SUCCESS:
      return Object.assign({}, populateAuditLogs(state, action.allItem), {latestAction: new Date()});

    case customerConstants.SWITCH_CUSTOMER:
      return state;//Object.assign({}, resetData(state, action.customerId));
    case userConstants.LOGIN_SUCCESS:
      return state

    case companyConstants.DELETE_PERSON_REQUEST:
      return Object.assign({}, updateCache(state, {error: "", deleteloading: true}, action.customerId), {latestAction: new Date()})
    case companyConstants.DELETE_PERSON_SUCCESS:
      return Object.assign({}, updateCache(deletePerson(state, action.personId), {deleteloading: false}, action.customerId), {latestAction: new Date()})
    case companyConstants.DELETE_PERSON_FAILURE:
      return Object.assign({}, updateCache(state, {error: "", deleteloading: false}, action.customerId), {latestAction: new Date()})

    case boardConstants.ISDELETE_MEMBERSHIP_REQUEST:
      if(action.userId === "") return state
      return Object.assign({}, boardCheck(state, action.boardId, action.userId, null), {latestAction: new Date()})
    case boardConstants.ISDELETE_MEMBERSHIP_SUCCESS:
      if(action.userId === "") return state
      return Object.assign({}, boardCheck(state, action.boardId, action.userId, action.deleteIds), {latestAction: new Date()});
    case boardConstants.ISDELETE_MEMBERSHIP_FAILURE:
      if(action.userId === "") return state
      return Object.assign({}, boardCheck(state, action.boardId, action.userId, true), {latestAction: new Date()})

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});

    default:
      return state
  }
}
